import { expose_insured_goods } from 'public/src/pages/cart_v2/common/analysis/item/insurePriceTag'

export function useInsurePriceTagEvent() {
  const onExpose = ({ itemOpts: { data, event } }) => {
    if (event?.type == 'insurePrice') {
      expose_insured_goods(data.value)
    }
  }

  return {
    onExpose,
  }
}
