<template>
  <div
    style="background: #f4f4f4"
    class="cart-v2"
  >
    <div style="min-width: 800px;">
      <CartHeader />
      <div
        class="container-fluid-1200 mcheck-cart j-cart-content container-1350"
      >
        <CartEmpty
          v-if="!originCartNum"
        />
        <div
          v-show="originCartNum"
          class="row j-cart-list new-cart-container__wrapper"
        >
          <div
            class=" c-check c-check__cart-left c-check-bag j-check-bag new-cart-container__left"
            :class="{'col-sm-8':originCartNum}"
          >
            <!-- 购物车全场提示 -->
            <div 
              class="j-top-tpl cart-container__top"
            >
              <CartTop v-if="cartSumQuantity > 0 && !showSkeleton" />
            </div>
            <div
              class="j-shoppromo-tip-placeholder"
              style="height: 74px;display: none"
            ></div>

            <div
              v-show="originCartNum && !showSkeleton"
              class="j-shopbag-body"
            >
              <div class="j-vue-bag cart-list-wrap">
                <CartList @render-end="scrollToTarget" />
              </div>
            </div>
          </div>
          <div
            v-show="originCartNum && !showSkeleton"
            class="j-shopbag-sum new-cart-container__right"
          >
            <div class="j-vue-price cart-container-summary">
              <CartSummary />
            </div>
          </div>
        </div>
      </div>
      <ClientOnly>
        <modal-vue
          :show="Boolean(loadingShow)"
          :twobtn="false"
          :has-close="false"
          :force="true"
          :ok-class="'she-hide'"
          background="transparent"
        >
          <template #footer>
            <div
              class="la-ball-pulse la-ball-pulse-2x la-ball-pulse-black"
            >
              <div></div><div></div><div></div>
            </div>
          </template>
        </modal-vue>
        <CommonModalTip v-if="modalOptions.show" />
        <CartNegativeInfoDialog v-if="asyncComponentLoadedStatus.negativeInfoDialogIsLoaded" />
        <CartSoldOutDialog v-if="asyncComponentLoadedStatus.soldOutDialogIsLoaded" />
        <CartBatchDialog v-if="asyncComponentLoadedStatus.batchActiveIsLoaded" />
        <CartSoldOutItemDialog v-if="asyncComponentLoadedStatus.soldOutItemDialogIsLoaded" />
        <!-- 领券弹窗 -->
        <GetCouponDrawer v-if="carts.length && drawerGetCoupon" />
        <AddBuyModal v-if="asyncComponentLoadedStatus.cartAddBuyDrawIsLoaded" />
        <FullGiftModal v-if="asyncComponentLoadedStatus.cartFullGiftsDrawIsLoaded" />
      </ClientOnly>
    </div>
  </div>
  <div
    class="j-recommend-list cart-recommend-list container-fluid-1200 not-fsp-element new-cart-recommend-list"
    style="visibility:hidden;"
  >
    <RecommendWrapper v-if="renderComponents.includes('recommend')" />
  </div>
</template>

<script>
export default {
  name: 'CartContainer',
}
</script>
<script setup>
import { useStore } from 'vuex'
import { assignAnalysis } from './utils/abt'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'
import sendPageView from './common/analysis/pageView.js'
import { cartEmphasizePromotionAll, cartEmphasizePromotion } from './utils'
import { getQueryString } from '@shein/common-function'
import { defineAsyncComponent, onMounted, computed, defineExpose, onBeforeUnmount, nextTick, ref, watch } from 'vue'
import { getCouponDataForAddOnItem } from '@/public/src/pages/cart_v2/components/business/add-on/layout/typeCoupon/utils.js'
import { prefetchResource } from 'public/src/services/prefetchResource'
import { daEventCenter } from 'public/src/services/eventCenter'
import { USE_BFF_API_KEYS } from './common/constants'
import { web_cart_route_enter } from './utils/metricTagsConfig.js'
import UserInfoManager from 'public/src/services/UserInfoManager'
import CartHeader from './components/page/header/index.vue'
import CartTop from './components/page/top/index.vue'
import CartList from './components/page/list/CartList.vue'
import CartSummary from './components/page/summary/index.vue'
import CartEmpty from './components/page/empty-cart/index.vue'
import checkoutIncentive from './components/functional/cart-checkout-incentive/checkoutIncentive'
import GetCouponDrawer from 'public/src/pages/cart_v2/components/functional/get-coupon/drawer.vue'
// 使用核心版本注册，不包含额外的模块
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

const CartSoldOutDialog = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_soldOutDialog" */ './components/functional/cartDialog/CartSoldOutDialog.vue'))
const CartNegativeInfoDialog = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_negativeInfoDialog" */ './components/functional/cartDialog/CartNegativeInfoDialog.vue'))
const CommonModalTip = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_dialog" */'./components/functional/cartDialog/CartCommonModalTip.vue'))
const CartBatchDialog = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_dialog" */'./components/functional/cartDialog/CartBatchDialog.vue'))
const CartSoldOutItemDialog = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_soldOutItemDialog" */'./components/functional/cartDialog/CartSoldOutItemDialog.vue'))
const RecommendWrapper = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_bottom_content" */'public/src/pages/cart_v2/components/page/recommend/index.vue'))
const AddBuyModal = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_pick_item" */'public/src/pages/cart_v2/components/functional/pick-item/AddBuyModal.vue'))
const FullGiftModal = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_pick_item" */'public/src/pages/cart_v2/components/functional/pick-item/FullGiftModal.vue'))

daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-7-2' })
daEventCenter.addSubscriber({ modulecode: '1-7-3' })
daEventCenter.addSubscriber({ modulecode: '1-7-5' })
daEventCenter.addSubscriber({ modulecode: '1-7-7' })
const { state, commit, dispatch } = useStore()

const originCartNum = computed(() => state.originCartNum || 0)
const cartSumQuantity = computed(() => cartInfo.value?.cartSumQuantity || 0)
const showSkeleton = computed(() => state.showSkeleton)
const modalOptions = computed(() => state.modalOptions)
const loadingShow = computed(() => state.loadingShow)
const asyncComponentLoadedStatus = computed(() => state.asyncComponentLoadedStatus)
const cartInfo = computed(() => state.cartInfo)
const carts = computed(() => state.carts)
const drawerGetCoupon = computed(() => state.drawerGetCoupon)
const filterLabel = computed(() => state.filterState.filterLabel)

const cartLureInfo = computed(() => state.checkoutState?.cartLureInfo || {})
const behaviorShow = computed(() => {
  let abtStrValue = state.cartAbtInfo?.UserBehaviorTips?.param?.CartBehaviorShow
  if (!abtStrValue || abtStrValue == 'Hide') return false
  return true
})

watch(() => state.mallCartBiData, (data) => {
  if (data?.trendStoreCodeList?.length) {
    exposeScenesAbtAggregator.addEvent({
      daId: '1-7-3-22',
      extraData: {
        scenes: 'store_trends_logo',
        type: data.trendStoreCodeList.join(','),
      },
    })
  }
  if (data?.holidayDeliveryExposeScene) {
    exposeScenesAbtAggregator.addEvent({
      daId: '1-7-3-22',
      extraData: {
        scenes: data.holidayDeliveryExposeScene,
      },
    })
  }
  if(data?.rankListExposeScenesAbt?.scenes) {
    exposeScenesAbtAggregator.addEvent({
      daId: '1-7-3-22',
      extraData: {
        scenes: data?.rankListExposeScenesAbt?.scenes, // ranklist_detail_tips"
        type: data?.rankListExposeScenesAbt?.type,
      }
    })
  }
}, {
  once: true,
})

watch(() => filterLabel.value, (n) => {
  if (typeof window == 'undefined') return
  if (n?.biData) {
    exposeScenesAbtAggregator.addEvent({
      daId: '1-7-3-22',
      extraData: {
        coupon_select: n?.biData?.couponSelect || 0
      }
    })
  }
}, { immediate: true, once: true })

const handleIncentiveStart = () => {
  if(typeof window == 'undefined') return
  exposeScenesAbtAggregator.addEvent({
    daId: '1-7-3-22',
    extraData: {
      benefitpop_available_point: cartLureInfo.value.biData?.benefitPopAvailablePoint || '-',
      type: 'benefit_pop',
    },
  })
  checkoutIncentive.initialize(cartLureInfo.value)
}

const onCreated = () =>{
  if(typeof window != 'undefined') {
    assignAnalysis({ abtInfo: state.cartAbtInfo })
  }
  dispatch('initSsrData').then(() => {
    if(typeof window != 'undefined') {
      nextTick(()=>{
        let $cartSkeleton = document.querySelector('.j-cart-skeleton')
        let $recommendList = document.querySelector('.j-recommend-list')
        $cartSkeleton && ($cartSkeleton.style.display = 'none')
        $recommendList && ($recommendList.style.visibility = 'visible')
        commit('updateState', { key: 'showSkeleton', value: false })
      })
      const getPageViewParames = () => {
        return {
          row_count: cartInfo.value?.effectiveProductLineSumQuantity || 0,
          special_count: state.mallCartBiData?.onlyXLeftProductLineSumQuantity || 0,
          almostsoldout: state.mallCartBiData?.almostSoldOutProductLineSumQuantity || 0,
          mallswitch: state.mallCartBiData?.otherMallProductLineSumQuantity || 0,
          skuswitch: state.mallCartBiData?.otherAttrProductLineSumQuantity || 0,
          rrp: state.mallCartBiData?.showRrpProductLineSumQuantity || 0,
          bag_goods_count: cartSumQuantity.value || 0,
          mall_code: state.mallCartList?.map(item => item.mall_code).join(',') || '',
          label_id: state.filterState.selectFilterLabel?.labelId || 0,
          all_row_count: cartInfo.value?.allProductLineSumQuantity || 0,
        }
      }
      sendPageView(getPageViewParames())
      setTimeout(() => {
        handleIncentiveStart()
      }, 0)
    }
  })
}

onCreated()

const oncartvisibilitychange = () => {
  const active = document.visibilityState !== 'hidden'
  if (active) {
    window.cartCheckLogin = UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })
  } else {
    window.cartCheckLogin = null
  }
}

const emphasize = () => {
  nextTick(function() {
    //定位打折限购报错
    if ( getQueryString({ key: 'isDiscountLimit' }) || getQueryString({ key: 'isFlashLimit' })) {
      let LimitIds = []
      let promotionOver = {}
      carts.value.filter(cartItem => [11, 24].includes(+cartItem.aggregateProductBusiness?.type_id) && cartItem.aggregateProductBusiness?.overLimit == 1).forEach(item => {
        if(!promotionOver[item.aggregateProductBusiness?.promotion_id]) {
          promotionOver[item.aggregateProductBusiness?.promotion_id] = true
          LimitIds.push(item.aggregateProductBusiness?.promotion_id)
        }
      })

      if( LimitIds.length ){
        cartEmphasizePromotionAll(LimitIds, { shake: true, changeColor: true, scroll: true })
        return
      }
    }
  })
}

onMounted(() => {
  web_cart_route_enter()
  if ('scrollRestoration' in window.history){
    window.history.scrollRestoration = 'manual'
  }
  emphasize()
  window.TPM?.publish('viewcart', {
    carts: carts.value,
    currency: gbCommonInfo.Currency,
    prices: { totalPrice: cartInfo.value.totalPrice }
  })
  handlerCouponSaveToast()
  dispatch('getUseBffApi', USE_BFF_API_KEYS)

  exposeScenesAbtAggregator.addEvent({
    daId: '1-7-3-22',
    extraData: {
      scenes: 'autopoint',
      max_available_point: cartInfo.value?.auto_use_points_info?.max_available_points || 0,
      auto_point_result: cartInfo.value?.auto_use_points_info?.auto_use_points > 0 ? 1 : 0,
      is_below_auto_use_point_threshold: cartInfo.value?.auto_use_points_info?.is_below_auto_use_point_threshold || 0,
    }
  })

  const hasLabel = carts.value.some(item => !!(item.product?.actTag || item.product?.actTags?.length))
  daEventCenter.triggerNotice({
    daId: '1-8-7-60',
    extraData: {
      scenes: 'userbehavior_tips',
      type: hasLabel ? behaviorShow.value ? 3 : 4 : behaviorShow.value ? 1 : 2
    }
  })

  requestIdleCallback(() => {
    prefetchResource.prefetchJs({
      prefetchList: [
        {
          chunkName: 'cartv2_dialog',
          relType: 'prefetch'
        },
        {
          chunkName: 'cartv2_pick_item',
          relType: 'prefetch'
        },
        {
          chunkName: 'add_on_v2',
          relType: 'prefetch'
        }
      ]
    })
  })
  if (carts.value?.length <= 6) {
    setRenderComponents()
  } else {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(async () => {
        setRenderComponents()
      })
    } else {
      setRenderComponents()
    }
  }

  window.cartCheckLogin = UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })
  document.addEventListener('visibilitychange', oncartvisibilitychange)
  window.addEventListener('beforeunload', () => {
    // 离开购物车时一次性冲洗聚合事件
    exposeScenesAbtAggregator.flush()
    exposeScenesAbtAggregator.destroy()
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('visibilitychange', oncartvisibilitychange)
})

const scrollToTarget = () => {
  // wait cart list render end then try scroll to target promotion group header
  const promotionId = getQueryString({ key: 'promotionId' })
  if (promotionId) {
    cartEmphasizePromotion(promotionId, { scroll: true })
  }
}


const handlerCouponSaveToast = () => {
  const couponData = getCouponDataForAddOnItem()
  let reqParams = null
  if(couponData?.couponInfo?.couponCode){
    reqParams = { coupon_code: couponData.couponInfo.couponCode }
  }
  if(reqParams){
    dispatch('fetchCartIndex', reqParams || {})
  }
}

const render_components = ['recommend']
let renderComponents = ref([])
// 分帧渲染
const setRenderComponents = () => {
  let renderComponentsLength = renderComponents.value?.length
  if (renderComponentsLength >= 1) {
    return
  }
  requestAnimationFrame(() => {
    renderComponents.value?.push(render_components[renderComponentsLength])
    setRenderComponents()
  })
}

const updateInfo = () => {
  dispatch('fetchCartIndex')
}

defineExpose({
  state,
  dispatch,
  commit,
  updateInfo
})
</script>

<style lang="less">
@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-6px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {

        transform: translate3d(6px, 0, 0);
    }
}
.promotion-header-shake {
  animation-name: shake;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.cart-red-dark {
  color: @color_red_dark;
}
.mcheck-cart{
  padding-bottom: 0;
  margin: 0 auto;
}
.c-check__cart-left{
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}
.c-check__cart-right{
  padding-left: 10px;
  border:none;
  &.c-summary-fixed{
    background: transparent;
    padding-top: 0;
  }
}
@media (min-width: 800px){
  .new-cart-container {
    &__right {
      position: sticky;
      top: 0;
    }
  }
  .cart-container__top{
    position: sticky;
    top: 0;
    z-index: @zindex-header;
    background: #f4f4f4;
  }
}

.new-cart-container {
  &__wrapper {
    display: flex;
    align-items: flex-start;
  }
  &__left {
    width: 66.66666667%;
  }
  &__right {
    width: 33.33333333%;
    // min-width: 320px;
    max-width: 450px;
  }
  // .col-sm-4{
  //   float: none!important;
  // }
}
/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  height: 44px;
  margin-top: -22px;
  z-index: @zindex-swiper-arrow;
  cursor: pointer;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev {
  left: 10px;
  right: auto;
}
.swiper-button-next {
  right: 10px;
  left: auto;
}
/* 定义关键帧动画 */
@keyframes highlight {
  /* 动画分四个阶段：从原始色到高亮色，再回到原始色，然后循环两次 */
  0%, 50%, 100% {
    background: transparent;
    z-index: 99;
  }
  25%, 75% {
    background: rgba(yellow, 0.1);
    z-index: 99;
  }
}

/* 应用动画到具体目标元素上 */
.highlight-animate {
  position: relative;
  /* 整个动画持续 800ms (400ms 高亮 * 2)，不延迟开始，执行一次 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -99;
    animation: highlight 800ms linear 0s 1;
    pointer-events: none;
  }
}
.sweep-light {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, transparent, rgba(255,255,255,.6), transparent);
    animation: sweepLight 6s linear infinite;
  }
}
@keyframes sweepLight {
  0% {
    transform: translate(-100%);
  }
  10% {
    transform: translate(100%);
  }
  100% {
    transform: translate(100%);
  }
}

</style>

<style lang="less" scoped>

.container-1350 {
  min-width: 800px;
  max-width: 1370px;
}
@media (min-width: 1366px) {
  .container-1350 {
    padding-left: 45px;
    padding-right: 45px;
    max-width: 1440px;
  }
}

.c-modal-wrap {
  z-index: 400000;
}
</style>

