import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { getCookie } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
daEventCenter.addSubscriber({ modulecode: '1-7-3' })
daEventCenter.addSubscriber({ modulecode: '1-7-7' })
daEventCenter.addSubscriber({ modulecode: '1-7-1' })

export const exposeCancelledTipReport = () => {
  return {
    id: '1-7-7-29'
  }
}

export const clickCancelledTipViewReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-7-30',
    extraData: {}
  })
}

export const exposeCancelledGoodsPopupReport = (data) => {
  daEventCenter.triggerNotice({
    daId: '1-7-7-31',
    extraData: {
      order_id: data.orderId,
    }
  })
}

export const clickCancelledGoodsPopupAllReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-7-32',
    extraData: {}
  })
}

export const clickSelectSomeProductReport = (data = {}) => {
  daEventCenter.triggerNotice({
    daId: '1-7-3-10',
    extraData: {
      select_type: 1,
      is_select: data.isChecked ? 0 : 1,
      scene: 'cancelled_popup',
    }
  })
}

export const clickCancelledGoodsPopupCloseReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-7-33',
    extraData: {}
  })
}

export const clickAddBagReport = (goods = [], result) => {
  const cct = UserInfoManager.get({ key: 'oest' }) || ''
  const click_id = `${cct}${Date.now()}`
  goods.forEach(data => {
    const extraData = {
      result,
      button_type: 'add_bag',
      from: 'cancelled_goods_multiple',
      style: 'popup',
      postData: {
        sku_code: data.skuCode,
        sku: data.goodsSn,
        goods_id: data.goodsId,
        attrs: data.skuSaleAttr,
        mallCode: data.mallCode,
        click_id,
        goods_list: `${data.goodsId}\`${data.goodsSn}\`${data.productRelationID}\`${data.index + 1}\`1`,
        spu: data.productRelationID,
        quantity: 1,
        catId: '',
        price: data?.priceData?.unitPrice?.price?.usdAmount,
        amount: data?.priceData?.unitPrice?.price?.amount,
        unit_discount: Math.abs(Number.parseFloat(data?.discountSubscript?.value ?? 0)),
        index: data.index,
        abtest: '',
      }
    }
    daEventCenter.triggerNotice({
      daId: '1-7-1-1',
      extraData,
    })
  })
}

export const clickGoodsOneMoreReport = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-7-34',
    extraData: {}
  })
}

export const exposeOutOfStockReport = (item) => {
  daEventCenter.triggerNotice({
    daId: '1-7-3-31',
    extraData: {
      goodsInfo: `${item.goodsId}\`1\`-`,
      is_cancelled_popup: 1,
    }
  })
}
