<template>
  <div
    class="store-header-container"
    :class="{'not-platform-store': !isPlatForm && !isBatchActivePage}"
  >
    <div class="store-header_left">
      <slot></slot>
      <div
        v-tap="analysisData('1-7-1-19')"
        v-expose="analysisData('1-7-1-18')"
        class="store-header-content"
        @click="goToStore"
      >
        <div class="store-header">
          <template v-if="preferredSellerStore == 1">
            <preferred-seller-store-icon :public-cdn="locals.PUBLIC_CDN" />
          </template>
          <template v-else>
            <sui_icon_store_20px
              v-if="isNewStoreIcon"
              size="20px"
            />
            <i
              v-else
              class="sh_pc_sui_icon_store_20px store-icon"
            ></i>
          </template>
          <span
            class="store-title"
            :class="{'seller-store-title': preferredSellerStore == 1}"
          >{{ shopName }}</span>
          <img
            v-if="!!storeTrendLogo"
            class="trend-icon"
            :src="storeTrendLogo"
          />
          <sui_icon_more_right_14px
            v-if="shopName.toLowerCase() != 'shein' && storeCode != 'shein' && storeCode != 1 && isSheinStore != 1"
            :is-rotate="locals.GB_cssRight"
            size="14px"
          />
        </div>
      </div>
    </div>

    <!-- 领取优惠券入口 -->
    <get-coupon
      v-if="showGetCouponEntry"
      class="store-get-coupon"
      :store-code="storeCode"
      :mall-code="mallCode"
    />
  </div>
</template>

<script>
export default {
  name: 'StoreHeader',
}
</script>
<script setup>
import { computed, defineProps } from 'vue'
import preferredSellerStoreIcon from 'public/src/pages/cart_v2/components/functional/common/PreferredSellerStoreIcon.vue'
import { stringifyQueryString } from '@shein/common-function'
import { sui_icon_store_20px, sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'
import { useStore } from 'vuex'
import GetCoupon from 'public/src/pages/cart_v2/components/functional/get-coupon/index.vue'

const { state } = useStore() 

const props = defineProps({
  shopName: {
    type: String,
    default: ''
  },
  storeCode: {
    type: String,
    default: ''
  },
  storeType: {
    type: [String, Number],
    default: ''
  },
  storeTrendLogo: {
    type: String,
    default: ''
  },
  hasStoreCoupon: {
    type: Boolean,
    default: false,
  },
  preferredSellerStore: {
    type: [String, Number],
    default: 0
  },
  storeRouting: {
    type: String,
    default: ''
  },
  mallCode: {
    type: [String, Number],
    default: ''
  },
  isBatchActivePage: {
    type: Boolean,
    default: false
  },
  isSheinStore: {
    type: String,
    default: '0'
  }
}) 

const goToStore = () => {
  if (props.storeRouting){
    let url = props.storeRouting + (props.storeRouting.indexOf('?') > -1 ? '&' : '?') + stringifyQueryString({
      queryObj: {
        rule_poskey: 'CartShopItemList',
      }
    }) 
    window.open(url, '_blank' )
  }
}

const analysisData = (id) =>{
  return {
    id: id,
    once: false,
    data: {
      store_code: props.isSheinStore == '1' ? '-' : (props.storeCode || '-'),
      store_tp: props.isSheinStore == '1' ? '-' : props.storeType == 1 ? 1 : 2,
      preferred_seller: props.isSheinStore == '1' ? '-' : props.preferred_seller_store == 1 ? 1 : 0,
      store_label: props.isSheinStore == '1' ? '-' : props.storeTrendLogo ? 'trends' : '-',
    }
  }
}
const locals = computed(() => state.locals)
const isNewStoreIcon = computed(() => state.cartAbtInfo.storeiconchange?.param?.storeiconchange === 'new')
// shop领券入口
const isPlatForm = computed(() => state.isPlatForm)
const showGetCouponsByAbt = computed(() => state.cartAbtInfo?.CartGetCoupons?.p === 'request=yes&show=yes')
let showGetCouponEntry = computed(() => {
  if (isPlatForm.value && showGetCouponsByAbt.value && props.hasStoreCoupon && props.storeType == '1') {
    return true
  }
  return false
})
</script>
<style lang="less">
.store-header-container{
  background-color: #FFFFFF;
  line-height: 18px;
  padding: 16px 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
  .store-header_left {
    display: flex;
    align-items: center;
    min-width: 0;
  }
  .store-header-content{
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  .store-header{
    padding: 0;
    cursor: pointer;
    width: 100%;
    top:0;
    display: flex;
    align-items: center;
    .store-title{
      color: @sui_color_gray_dark1;
      font-size: 16px;
      margin-right: 4px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      margin-left: 4px;
    }
    .seller-store-title {
      padding-bottom: 0;
    }
    .store-icon{
      position: relative;
      top: -1px;
    }
    /* stylelint-disable-next-line selector-class-pattern */
    .sh_pc_sui_icon_store_20px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS41IDExTDIuODA3NzMgM0gxNy4xOTIzTDE4LjUgMTFIMTdWMThIM1YxMUgxLjVaTTMuMjY1MTEgOS41SDdWNC41SDQuMDgyNDRMMy4yNjUxMSA5LjVaTTguNSA5LjVIMTEuNVY0LjVIOC41VjkuNVpNMTMgOS41SDE2LjczNDlMMTUuOTE3NiA0LjVIMTNWOS41Wk00LjUgMTFWMTYuNUgxNS41VjExSDQuNVoiIGZpbGw9ImJsYWNrIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
    }
  }
  &.not-platform-store{
    margin-top: 5px;
  }
  .store-get-coupon {
    margin-left: 8px;
    white-space: nowrap;
  }
  .trend-icon {
    width: 44px;
    height: 14px;
  }
}
</style>
