<template>
  <div style="position: relative">
    <div
      class="j-shopbag-title-height"
      style="height: 116px;display: none"
    ></div>
    <div class="j-shopbag-title cart-list__header">
      <div class="cart-list__header-title">
        <div class="new-cart__title-left">
          <h4
            :aria-label="`${language.SHEIN_KEY_PC_22381}(${allQuantity})`"
            tabindex="0"
          >
            <s-checkbox
              label="all"
              :model-value="checkAllItem"
              :disabled="isDisabledCheckedAll"
              :outside-check="true"
              :gap="'4px'"
              @change="handleCheckedAll"
            >
              <span class="new-cart__title-text">{{ `${language.SHEIN_KEY_PC_22381} (${allQuantity})` }}</span>
            </s-checkbox>
          </h4>
          <ClientOnly>
            <RealTimeLabels
              v-if="usefulRealTimeLabels.length"
              :labels="usefulRealTimeLabels"
            />
          </ClientOnly>
        </div>
        <div
          v-show="allProductLineSumQuantity > 1"
          v-enterkey
          class="cart-list__header-batch"
          tabindex="0"
          role="button"
          @click="handleBatchItems"
        >
          <sui_icon_select_16px_1 size="16px" />
          <span>{{ language.SHEIN_KEY_PC_15659 }}</span>
        </div>
      </div>
    </div>
    <!-- 筛选栏 -->
    <CartListFilter
      v-if="showCartListFilter && allFilterLabels.length > 0"
      :all-filter-labels="allFilterLabels"
      :model-value="filterTagId"
      @update:model-value="handleFilterClick"
    />
    <!-- 筛选补充信息 -->
    <FilterAdditionalInfo v-show="!batchActive" />
    <div
      class="cart-list__content"
      :class="[
        isPlatForm ? 'cart-list__platform': !showOBMStore && showThreeStore ? 'show-three-title' : 'cart-list__platform-not',
        {
          'show-obm-title': showOBMStore,
        }
      ]"
    >
      <div
        v-if="!allProductLineSumQuantity"
        class="empty-bag__delete-after"
      >
        <CartEmpty />
      </div>
      <template
        v-for="mallCartItem in mallCartList"
        :key="mallCartItem.mall_code"
      >
        <MallHeader
          v-if="isPlatForm"
          :key="mallCartItem.mall_code"
          :mall-name="mallCartItem.mall_name"
          :mall-desc="mallCartItem.mallDesc"
          :language="language"
          :shipping-activity-data="mallCartItem.shippingActivityData"
          :promotion-data="mallCartItem.promotionData"
          :locals="locals"
          :mall-code="mallCartItem.mall_code"
        >
          <s-checkbox
            :label="mallCartItem.mall_code"
            :disabled="isDisabled(mallCartItem.cart_id_list)"
            :model-value="getChecked(mallCartItem.cart_id_list)"
            :gap="'4px'"
            :outside-check="true"
            @change="(res) => handleGroupCheckedItem(res, mallCartItem)"
          />
        </MallHeader>
        <template v-else>
          <template
            v-for="(bottomPromotion) in fullPromotionData"
            :key="bottomPromotion.data.promotion_id"
          >
            <full-gift-header
              v-if="bottomPromotion.data && bottomPromotion.data.type_id == 4"
              :promotion-info="bottomPromotion"
              style="padding: 0;"
            />
            <full-add-on-header
              v-else-if="bottomPromotion.data && bottomPromotion.data.type_id == 22"
              :promotion-info="bottomPromotion"
              style="padding: 0;"
            />
          </template>
        </template>
        <template
          v-for="(shop, i) in mallCartItem.shops"
        >
          <template v-if="shop.store_code && (i < (isClient ? mallCartItem.shops.length : 6))">
            <store-header
              :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName"
              :store-code="shop.store_code"
              :shop-name="shop.shopName"
              :store-type="shop.store_type"
              :store-trend-logo="shop.store_trend_logo"
              :has-store-coupon="shop?.hasStoreCoupon == '1'"
              :preferred-seller-store="shop.preferred_seller_store"
              :store-routing="shop.storeRouting"
              :mall-code="mallCartItem.mall_code"
            >
              <s-checkbox
                :label="mallCartItem.mall_code +','+shop.store_code"
                :model-value="getChecked(shop.cart_id_list)"
                :disabled="isDisabled(shop.cart_id_list)"
                :gap="'4px'"
                :outside-check="true"
                @change="(res) => handleGroupCheckedItem(res, shop)"
              />
            </store-header>
            <StoreShipping
              v-if="shop?.shippingActivityData"
              :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName"
              :shipping-activity-data="shop.shippingActivityData"
              :mall-code="mallCartItem.mall_code"
            />
          </template>
          <template
            v-for="(content) in shop.contentData"
          >
            <promotion-header
              v-if="content.groupHeadInfo && content.groupHeadInfo.data"
              :key="'promotion-'+content.groupHeadInfo.data.promotion_id"
              :promotion-info="content.groupHeadInfo"
            >
              <s-checkbox
                v-if="content.groupHeadInfo.data.is_shop_group != 1 && ![4, 22].includes(+content.groupHeadInfo.data.type_id)"
                :label="content.groupHeadInfo.data.promotion_id"
                :model-value="getChecked(content.groupHeadInfo.data.cart_id_list)"
                :disabled="isDisabled(content.groupHeadInfo.data.cart_id_list)"
                :gap="'4px'"
                :outside-check="true"
                @change="(res) => handleGroupCheckedItem(res, content.groupHeadInfo.data)"
              />
            </promotion-header>
            <CartItem
              v-for="item in content.productLineInfoList.filter(i => cartIds.indexOf(i.id) < onlySsrRenderNum)"
              :key="item.id"
              :item="item"
              :index="cartIds.indexOf(item.id)"
            />
            <ClientOnly
              v-for="item in content.productLineInfoList.filter(i => cartIds.indexOf(i.id) >= onlySsrRenderNum && ( renderCount ? renderCount > cartIds.indexOf(i.id) : cartIds.indexOf(i.id) >= renderCount))"
              :key="item.id"
            >
              <CartItem
                :key="item.id"
                :item="item"
                :index="cartIds.indexOf(item.id)"
              />
            </ClientOnly>
          </template>
        </template>
        <div 
          v-if="mallCartItem.clientMultiLangKeyTip && mallCartItem.clientMultiLangKeyTip.negativeInfoEnter && negativeInformation[mallCartItem.mall_code]"
          v-expose="analysisData('1-7-3-19', mallCartItem.mall_code)"
          v-tap="analysisData('1-7-3-20', mallCartItem.mall_code)"
          class="cart-negative-info"
          :class="{'multi-mall': isPlatForm}"
          @click="hanlderNegativeInformation(mallCartItem.mall_code)"
        >
          <sui_icon_info_16px_1 size="16px" />
          <span>{{ mallCartItem.clientMultiLangKeyTip.negativeInfoEnter }}</span>
          <span class="negative-view">
            {{ mallCartItem.clientMultiLangKeyTip.negativeInfoView }}
          </span>
        </div>
      </template>
      <template v-if="soldoutItems.length">
        <CartSoldOutHeader v-show="!doFilter" />
        <CartSoldOutItems
          v-show="!doFilter"
        />
        <div
          v-show="!doFilter"
          class="place-holder"
          style="padding-bottom: 8px;background: #fff;"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartList'
} 
</script>
<script setup>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import { sui_icon_select_16px_1, sui_icon_info_16px_1 } from '@shein-aidc/icon-vue3'
import RealTimeLabels from 'public/src/pages/cart_v2/components/functional/cartRealtimeBehaviorLable/RealTimeLables'
import MallHeader from 'public/src/pages/cart_v2/components/functional/cartList/MallHeader.vue'
import StoreHeader from 'public/src/pages/cart_v2/components/functional/cartList/StoreHeader.vue'
import PromotionHeader from 'public/src/pages/cart_v2/components/functional/cartList/PromotionHeader.vue'
import StoreShipping from 'public/src/pages/cart_v2/components/functional/cartList/StoreShipping.vue'
import MallShpping from 'public/src/pages/cart_v2/components/functional/cartList/MallShipping.vue'
import CartItem from './CartListItem.vue'
import FullGiftHeader from 'public/src/pages/cart_v2/components/functional/cartList/FullGiftHeader.vue'
import FullAddOnHeader from 'public/src/pages/cart_v2/components/functional/cartList/FullAddOnHeader.vue'
import CartEmpty from 'public/src/pages/cart_v2/components/functional/cartEmpty/index.vue'
import CartSoldOutHeader from 'public/src/pages/cart_v2/components/functional/cartList/SoldOutHeader.vue'
import CartListFilter from 'public/src/pages/cart_v2/components/functional/cart-filter/index.vue'
import FilterAdditionalInfo from 'public/src/pages/cart_v2/components/functional/filter-additional-info/index.vue'
import CartSoldOutItems from 'public/src/pages/cart_v2/components/functional/cartList/CartSoldOutItems.vue'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'

const { state, getters, dispatch, commit } = useStore()
let onlySsrRenderNum = ref(6)
let renderCount = ref(6)
let isClient = ref(false)

const { modifyCartCheckStatus } = useModifyCartCheckStatus()

const emit = defineEmits(['renderEnd'])

const language = computed(() => state.language)
const isPlatForm = computed(() => state.isPlatForm)
const mallCartList = computed(() => state.mallCartList)
const cartItemMap = computed(() => state.cartItemMap)
const cartIds = computed(() => state.cartIds)
const allQuantity = computed(() => state.cartInfo.effectiveProductLineSumQuantity)
const allProductLineSumQuantity = computed(() => state.cartInfo.allProductLineSumQuantity)
const usefulRealTimeLabels = computed(() => {
  return state.carouselState.usefulLabels || []
})
const carts = computed(() => state.carts)
const checkAllItem = computed(() => {
  const validMallCarts = mallCartList.value.filter(item => item.type == '1')
  return validMallCarts.length && validMallCarts.every(cart => cart.is_checked == '1')
})
const selectFilterLabel = computed(() => state?.filterState?.selectFilterLabel)
const isDisabledCheckedAll = computed(() => {
  if (selectFilterLabel.value?.filterTagId !== 'all') return true // 筛选状态下禁止全选
  let normalItems = state.carts?.filter(item => item.isInvalid != '1') || [] // 购物车内所有告罄商品
  return normalItems.length ? false : true
})

const showOBMStore = computed(() => {
  return getters.showOBMStore
})
const showThreeStore = computed(() => {
  return getters.showThreeStore
})

const fullPromotionData = computed(() => {
  return state.promotionState.fullPromotionList?.filter(item => [4, 22].includes(+(item.data.type_id)))
})

const negativeInformation = computed(() => {
  return getters.negativeInformation
})

const soldoutItems = computed(() => {
  return state.soldoutItems
})

const doFilter = computed(() => {
  return filterTagId.value !== 'all'
})

const isDisabled = (cart_id_list) =>{
  return !cart_id_list.some(id => !cartItemMap.value[id].isDisabled)
}

const getChecked = (cart_id_list) =>{
  return cart_id_list.every(id => cartItemMap.value[id].is_checked == 1)
}

const handleBatchItems = async () => {
  commit('updateState', { key: 'loadingShow', value: true })
  await dispatch('clearFilter')
  commit('updateState', { key: 'loadingShow', value: false })
  commit('handleBatchActive', { value: true, checkSoldout: true })
}

const handleGroupCheckedItem = ( { label = '', checked = false }, mallCartItem) => {
  const onBeforeModify = () => {
    daEventCenter.triggerNotice({
      daId: '1-7-3-10',
      extraData: {
        select_type: 2,
        is_select: checked ? 0 : 1,
        position: 'page',
      }
    })
  }
  modifyCartCheckStatus({ cartIdList: mallCartItem.cart_id_list, operationType: checked ? 1 : 2, type: 'section', onBeforeModify })
}
const handleCheckedAll = ({ checked = false }) => {
  const onBeforeModify = () => {
    daEventCenter.triggerNotice({
      daId: '1-7-3-11',
      extraData: {
        is_select: checked ? 0 : 1,
      }
    })
  }
  const onAfterModify = () => {
    // if(checkAllItem.value && showDiscountDetails) {
    //   props.updateCheckedItemIds()
    // }
  }
  modifyCartCheckStatus({ operationType: checked ? 3 : 4, type: 'all', onBeforeModify, onAfterModify })
}

const setListItem = (list) => {
  if (renderCount.value >= state.cartInfo.effectiveProductLineSumQuantity) {
    emit('renderEnd')
    renderCount.value = 0
    return
  }
  requestAnimationFrame(() => {
    renderCount.value += 6
    setListItem()
  })
}

const analysisData = (id, mallCode) => {
  return {
    id: id,
    once: false,
    data: {
      mall_code: mallCode
    }
  }
}

// 筛选栏相关
const batchActive = computed(() => state.batchActive)
const filterTagId = computed(() => state?.filterState?.selectFilterLabel?.filterTagId)
const allFilterLabels = computed(() => state?.filterState?.allFilterLabels)
// 总商品数小于等于4不展示筛选
const showCartListFilter = computed(() => filterTagId.value == 'all' ? cartIds.value.length > 4 : true)
const handleFilterClick = async (value) => {
  // if(typeof window !== 'undefined' && !navigator.onLine) {
  // this.$toast(language.value?.SHEIN_KEY_PWA_31762)
  // return
  // }
  commit('updateState', { key: 'loadingShow', value: true })
  // 筛选状态更新后页面回到顶部重新刷新数据
  if (window.scrollY) { window.scrollTo({ top: 0 }) }
  let params = {}
  if (value !== 'all') {
    params = { filterTagId: value }
  }
  try {
    await dispatch('fetchCartIndex', params)
    commit('updateState', { key: 'loadingShow', value: false })
  } catch(e) {
    commit('updateState', { key: 'loadingShow', value: false })
    // console.log('error', e)
  }
}

onMounted(() => {
  if (window.requestIdleCallback) {
    window.requestIdleCallback(() => {
      setListItem()
    })
  } else {
    setListItem()
  }
  isClient.value = true
  const hasLabel = carts.value.some(item => item.product?.actTags?.find(item => item.hasAvailableTag === '1'))
  exposeScenesAbtAggregator.addEvent({
    daId: '1-7-3-22',
    extraData: {
      scenes: 'userbehavior_tips',
      type: hasLabel ?  3 : 1
    }
  })
})

const hanlderNegativeInformation = (mallCode) => {
  dispatch('showNegativeInfoDialog', mallCode)
}
</script>
<style lang="less">
.cart-table{
  display:table;
  width: 100%;
  .cart-row{
    display:table-row;
    list-style-type:none;
    .cart-row-cell{
      display:table-cell;
      vertical-align: top;
      text-align: left;
    }
    .cart-img{
      width: 50%;
      position: relative;
    }
    .cart-price{
      width: 20%;
      padding-bottom: 20px;
    }
    .cart-qty{
      width: 13%;
    }
    .cart-total{
      width: 17%;
      text-align: right;
    }
    .cart-item-cell{
      padding-left: 110px;
      position: relative;
      //bottom: 7px;
    }
  }
}
.cart-table-h{
  font-weight: bold;
  font-size: 14px;
  color: #222222;
  padding: 14px 0;
}
.cart-list__header{
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 16px 12px;
  background: #fff;
  .cart-list__header-title{
    padding-bottom: 4px;
  }
  .new-cart__title-left {
    display: flex;
    align-items: center;
  }
}
.cart-list__header-title {
  .flexbox();
  justify-content: space-between;
  align-items: center;
  >h4 {
    font-size: 20px /* rw: 18px */;
    color: #222222;
    display: inline-block;
    text-transform: capitalize;
    font-family: Arial-Black, Arial /* rw: Adieu */;
  }
}
.cart-list__header-batch {
  cursor: pointer;
  color: #222;
  font-size: 13px;
  >span {
    vertical-align: middle;
    font-weight: bold;
  }
}
.cart-list__content{
  margin-top: 10px;
  .cart-table + .warehouse-container, .cart-table + .sold-out-group{
    margin-top: 20px;
  }
  .empty-cart-container{
    padding-bottom: 24px;
  }
  // 档筛选栏存在且选中不为券标签时，需要隐藏10px的间距
  // &.adjust-margin {
  //    margin-top: -5px;
  // }
}
.cart-list__platform,.show-three-title{
  .common-group-header{
    padding: 0 16px;
    height: 1px;
    background: #fff;
  }
  .cart-table + .cart_item-header, .cart_item-header + .cart_item-header, .cart-table + .common-group-header, .cart_item-header + .common-group-header{
    position: relative;
    &:before {
      border-bottom: 1px dashed #e5e5e5;
      content: "";
      width: calc(100% - 48px);
      display: block;
      top: 0;
      position: absolute;
    }
  }
  .mall-section-item + .mall-section-item, .cart-table + .sold-out-group{
    &:before {
      border-bottom: 0;
    }
  }
}
.cart-list__platform-not{
  .cart-table + .cart_item-header, .cart_item-header + .cart_item-header, .cart-table + .common-group-header, .cart_item-header + .common-group-header{
    margin-top: 10px;
  }
}
.show-obm-title{
  .cart_item-header,.store-header-container{
    margin-top: 5px;
    &:first-child{
      margin-top: 10px;
    }
  }
  /* stylelint-disable-next-line selector-max-specificity */
  .cart-table + .cart_item-header:not(.sold-out-group), .cart_item-header + .cart_item-header{
    margin-top: 5px;
    /* stylelint-disable-next-line selector-max-specificity */
    &:before {
      border-bottom: 0;
    }
  }
}
.show-three-title{
  .store-header-container{
    margin-top: 10px;
    &:first-child{
      margin-top: 0;
    }
  }
}
.cart_item-header{
  padding: 20px 24px 0;
  background: #FFFFFF;
  &.not-promoGoods{
    padding-bottom: 20px;
  }
  .cart-item__header-section{
    .flexbox();
    .space-between();
    .align-center();
    background: @sui_color_promo_bg;
    padding: 16px;
    >div{
      .padding-r(32px);
      flex: 1;
    }
    .cart_item-title{
      font-size: 16px;
      color: #000;
      font-weight: 700;
      margin-bottom: 4px;
      /* stylelint-disable-next-line selector-max-specificity */
      .c-count__down-time{
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
        font-weight: normal;
        color: @sui_color_highlight;
        margin-top: 0;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      &.full-title{
        font-size: 14px;
      }
    }
    .cart_item-desc{
      color: #000000;
      font-size: 13px;
      line-height: 15px;
      em{
        color: @sui_color_welfare_dark;
      }
    }
  }
}
.checkbox-item{
  // display: inline-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
  .newcomer-guide__checkbox {
    .sui-checkbox__label-text {
      padding-left: 0;
    }
  }
}
.cart-list__header-sticky{
  position: sticky;
  top: 0;
  z-index: @zindex-out;
}
.top-container-fixed {
  position: fixed;
  top: 0;
  z-index: 10;
}

.cart-list__content {
  /* stylelint-disable-next-line selector-max-specificity */
  .cart-table+.cart_item-header:not(.sold-out-group) {
    margin-top: 1px;
  }
  .cart_item-header.promo-group {
    background: #FFF6F3;
    padding: 12px 16px;
    margin-top: 1px;
    section {
      padding: 2px 0;
    }
  }
  .cart_item-header.not-promoGoods {
    padding: 0 16px;
    margin-top: 1px;
  }
  .cart_item-header:first-child {
    margin-top: 5px;
  }
  .common-group-header {
    padding: 0 16px;
    height: 1px;
    background: #fff;
  }
  .place-holder + .common-group-header {
    position: relative;
    &:before {
      border-bottom: 1px dashed #e5e5e5;
      content: "";
      width: calc(100% - 32px);
      display: block;
      top: 0;
      position: absolute;
    }
  }
  // 单mall间距与负向信息样式
  &:not(.cart-list__platform) {
    .cart-negative-info {
      background: unset;
    }
    .cart_item-header {
      margin-top: 5px;
    }
  }
}
.new-cart__title-text {
  font-size: 20px;
  font-weight: 900;
  font-family: 'Arial Black';
  color: @sui_color_gray_dark1;
  margin-right: 12px;
}
.cart-list__header-batch {
  >*:not(:last-child) {
    margin-right: 4px;
  }
}

.cart-negative-info {
  margin-top: 1px;
  font-size: 14px;
  padding: 12px 16px 16px;
  background: #fff;
  color: @sui_color_gray_dark3;
  display: flex;
  >*:not(:last-child) {
    margin-right: 4px;
  }
  .negative-view {
    cursor: pointer;
    color: @sui_color_link;
  }
}
</style>

<style lang="less" scoped>
.cart-list__content .all-store-header {
  margin-top: 0px;
}
.empty-bag__delete-after{
  text-align: center;
  background: #FFFFFF;
  min-height: 340px;
}
</style>

