import { setSessionStorage } from '@shein/common-function'
const removeCartItemUnUseField = (cartItem = {}) => {
  delete cartItem.product.product_promotion_info
  delete cartItem.product.qs_stock
  delete cartItem.product.brand_type
  delete cartItem.product.brand_code
  delete cartItem.product.brand_logo
  delete cartItem.product.corner_brand_left
  delete cartItem.product.corner_brand_right
  delete cartItem.product.corner_series_left
  delete cartItem.product.corner_series_right
  delete cartItem.product.customization_flag
  delete cartItem.product.is_on_sale
  delete cartItem.product.special_price
  delete cartItem.product.suggested_sale_discount
  delete cartItem.product.suggested_unit_discount
  delete cartItem.product.unit_discount_before_point
  delete cartItem.product.insurable_time
  delete cartItem.product.sale_price_before_insured
  delete cartItem.product.special_price_start
  delete cartItem.product.special_price_end
  delete cartItem.product.original_img
  delete cartItem.product.is_stock_enough
  delete cartItem.product.cost
  delete cartItem.product.is_virtual_stock
  delete cartItem.product.supplier_id
  delete cartItem.product.supplier_linkman
  delete cartItem.product.goods_desc
  delete cartItem.product.goods_detail_desc
  delete cartItem.product.sizeTemplate
  delete cartItem.product.is_return
  delete cartItem.product.skc_return
  delete cartItem.product.is_pre_sale
  delete cartItem.product.is_pre_sale_end
  delete cartItem.product.comment
  delete cartItem.product.promotion
  delete cartItem.product.goods_img_webp
  delete cartItem.product.original_img_webp
  delete cartItem.product.weight
  delete cartItem.product.skcWeight
  delete cartItem.product.special_price_old
  delete cartItem.product.retail_price_mkc
  delete cartItem.product.special_price_mkc
  delete cartItem.product.currency_mkc
  delete cartItem.product.is_clearance
  delete cartItem.product.flash_goods
  delete cartItem.product.isPriceConfigured
  delete cartItem.product.attr_value_std_name
  delete cartItem.product.has_configure_price
  delete cartItem.product.productDetails
  delete cartItem.product.comment_num
  delete cartItem.product.comment_rank_average
  delete cartItem.product.series
  delete cartItem.product.series_logo
  delete cartItem.product.brand_badge
  delete cartItem.product.promotionCornerTspIds
  delete cartItem.product.premiumFlagNew
  delete cartItem.product.ruleType
  delete cartItem.product.series_info_list
  delete cartItem.every_body_price
  delete cartItem.free_policy_limit
  delete cartItem.free_activity_limit
  delete cartItem.freight_activity_limit
  delete cartItem.freight_free_rule_id
  delete cartItem.real_quick_ship
  delete cartItem.promotion_discount
  delete cartItem.single_coupon_discount
  delete cartItem.single_total_promotion_discount
  delete cartItem.single_total_promotion_discount_without_prime
  delete cartItem.single_other_discount
  delete cartItem.promotion_product_mark
  delete cartItem.unit_price_before_coupon
  delete cartItem.unit_price_before_point
  delete cartItem.single_every_body_promotion_discount
  delete cartItem.single_exclusive_promotion_discount
  delete cartItem.single_point_discount
  delete cartItem.suggestedSalePriceInfo
  delete cartItem.totalPrice
  delete cartItem.isShowReselect
  delete cartItem.isShowSimilar
  delete cartItem.njAddCartRawOrder
  delete cartItem.szSortOrder
  delete cartItem.discountNum
  delete cartItem.per_coupon_discount
  delete cartItem.pre_coupon_discount_rate
  delete cartItem.promotion_status
  delete cartItem.relatedProductIds
}
export function handleCartList(mallCarts = []) {
  let cartList = []
  let mallShippingInfo = {}
  let integrityPromotionInfo = {}
  // let mallCarts = state.mallCartInfo.mallCarts || []
  mallCarts?.forEach(mall => {
    let mall_level = mall.mall_code ? {
      append_id_list: [],
      cart_id_list: []
    } : null
    mall.shops?.forEach(shop => {
      let shop_level = shop.store_code ? {
        append_id_list: [],
        cart_id_list: []
      } : null
      shop.contentData?.forEach(content => {
        let content_level =  (content.groupHeadInfo?.promotionTips?.html_text || content.groupHeadInfo?.promotionTips?.text) ? {
          append_id_list: [],
          cart_id_list: []
        } : null
        let promotionGroupId = content.groupHeadInfo?.data?.promotion_id || ''
        if(promotionGroupId) {
          content.groupHeadInfo.data.store_id = content.groupHeadInfo.data.is_shop_group == 1 ? shop.store_code : '-'
          if(integrityPromotionInfo[promotionGroupId]) {
            integrityPromotionInfo[promotionGroupId].data = content.groupHeadInfo.data
          } else {
            integrityPromotionInfo[promotionGroupId] = { data: content.groupHeadInfo.data }
          }
        }
        content.productLineInfoList?.forEach(product => {
          removeCartItemUnUseField(product)
          
          if(mall.type == '-1') { // 已售罄
            return
          }
          if(promotionGroupId && product.is_checked == 1 && !content.groupHeadInfo.data.hasCartItemChecked) {
            content.groupHeadInfo.data.hasCartItemChecked = true
          }
          // 促销组头id
          product.promotionGroupId = promotionGroupId
          // 是否可切mall
          product.canChangeMallInfo = product?.aggregateProductBusiness?.changeMallInfo?.isOtherMall == '1'
          // 是否可切销售属性
          product.canChangeGoodInfo = product?.aggregateProductBusiness?.changeGoodInfo?.isOtherAttr == '1'
          // 新人专享价商品
          product.isNewcomerItem = product?.aggregateProductBusiness?.showNewUsersBonus == '1'
          product.isDisabled = product?.aggregateProductBusiness?.showNewUsersBonus == '1' || product?.aggregateProductBusiness?.isPresent == '1' || product?.aggregateProductBusiness?.isAddBuy == '1' || product?.isPayMemberGift == '1' || product?.isInvalid == '1' || product.canChangeMallInfo || product.canChangeGoodInfo
          if(mall_level) {
            mall_level.append_id_list.concat(product.appendIds || [])
            mall_level.cart_id_list.push(product.id)
          }
          if(shop_level) {
            shop_level.append_id_list.concat(product.appendIds || [])
            shop_level.cart_id_list.push(product.id)
          }
          if(content_level) {
            content_level.append_id_list.concat(product.appendIds || [])
            content_level.cart_id_list.push(product.id)
          }
        })
        cartList = cartList.concat(content.productLineInfoList || [])
        if(content_level) {
          content.groupHeadInfo.data.append_id_list = [...new Set(content_level.append_id_list)]
          content.groupHeadInfo.data.cart_id_list = content_level.cart_id_list
        }
      })
      if(shop_level) {
        shop.append_id_list = [...new Set(shop_level.append_id_list)]
        shop.cart_id_list = shop_level.cart_id_list
      }
    })
    if(mall_level) {
      mall.append_id_list = [...new Set(mall_level.append_id_list)]
      mall.cart_id_list = mall_level.cart_id_list
    }
    if(mall.type == 0) {
      mallShippingInfo['platform'] = mall.shippingActivityData || {}
    } else if(mall.type == 1 || mall.type == -1) {
      mall.mall_code && (mallShippingInfo[`${mall.mall_code}`] = mall.shippingActivityData || {})
    }
  })
  return {
    cartList,
    mallShippingInfo,
    integrityPromotionInfo
  }
}

export function updateSessionOrderCache(cachedOrderIdList, billno) {
  const value = Array.from(new Set([...cachedOrderIdList, billno]))
  setSessionStorage({ key: 'CART_CACHE_ORDER_ID_LIST', value })
}
