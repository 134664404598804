<template>
  <section
    v-expose="analysisEvent('1-7-3-23')"
    class="cart-coupon-addon__container section promotion-sec not-fsp-element"
  >
    <!-- 优惠券凑单促销头组件,增加注释节点 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <div class="section-l">
      <component
        :is="addOnIconName"
        size="18px"
      />
    </div>
    <div class="section-r">
      <div class="coupon-addon-content">
        <div class="item-content-tip">
          <div 
            class="promotion-full-tip"
            v-html="couponInfo.promotionTips && couponInfo.promotionTips.html_text"
          ></div>
          <div
            v-if="couponInfo.data.isMeet == 0"
            class="item-content-tip__process"
          >
            <div
              class="item-content-tip__process-bg"
              :style="{ width: `${processCouponCount}%` }"
            ></div>
          </div>
        </div>
        <div
          v-if="couponInfo.data.isMeet == 0" 
          v-tap="analysisEvent('1-7-3-24')"
          class="cart_item-action"
          @click.stop="handlePromotionItemActionClick"
        >
          <span>{{ language.SHEIN_KEY_PC_15215 }}</span>
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </div>
      </div>
      <ClientOnly>
        <count-down-time
          v-if="couponInfo.data.is_count_down"
          class="coupon-addon__count-down"
          show-type="inline-promotion"
          :time-stamp="Number(couponInfo.data.end_time)"
          :show-end-tips="true"
        />
      </ClientOnly>
    </div>
  </section>
</template>
<script>
export default {
  name: 'CouponAddon'
}
</script>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { sui_icon_selected_24px, sui_icon_more_right_12px, sui_icon_coupon_discount_24px } from '@shein-aidc/icon-vue3'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
const AddItem = useCartAddItem()

const props = defineProps({
  couponInfo: {
    type: Object,
    default: () => ({})
  },
})

const { state } = useStore()

const language = computed(() => state.language)
const locals = computed(() => state.locals)
const addOnIconName = computed(() => {
  return props.couponInfo.data?.isMeet == '1' ? sui_icon_selected_24px : sui_icon_coupon_discount_24px
})

const processCouponCount = computed(() => {
  let targetPrice = Number(props.couponInfo.data?.additionInfoList?.[0].showTitlePrice?.amount)
  let progressDiffAmount = Number(props.couponInfo.data?.progressDiffAmount?.amount)
  let process = (targetPrice - progressDiffAmount) / targetPrice * 100
  if(process < 0) process = 0
  else if(process > 100) process = 100
  return process
})

const handlePromotionItemActionClick = () => {
  AddItem.show({
    coupon: props.couponInfo.data?.addItemParams?.couponCode,
    state: 'coupon_add',
  })
}

const analysisEvent = (id) => {
  return {
    id: id,
    data: {
      is_satisfied: props.couponInfo.data.isMeet == 0 ? 0 : 1,
      code: props.couponInfo.data?.addItemParams?.couponCode || '-'
    }
  }
}
</script>

<style lang="less" scoped>
.cart-coupon-addon__container {
  .section {
    height: 100%;
  }
  .section-r {
    max-width: unset;
    overflow: hidden;
  }
  .coupon-addon-content {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
  }
  .item-content-tip {
    margin-right: 12px;
    overflow: hidden;
    .promotion-full-tip {
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }    
    .item-content-tip__process {
      width: 100%;
      height: 3px;
      background-color: @sui_color_gray_light2;
      border-radius: 2px;
      margin-top: 4px;
    }
    .item-content-tip__process-bg {
      height: 3px;
      background-color: @sui_color_main;
      border-radius: 2px;
      transition: width .5s linear;
    }
  }
  .cart_item-action {
    color: #222;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
  }
  .coupon-addon__count-down {
    margin-top: 8px;
  }
}
</style>
