<template>
  <div
    class="j-cart-list-filter"
    :class="{
      'filter-sticky': isSticky || showScanLights,
    }"
    :style="{ 'top': (isSticky || showScanLights) ? stickyTop + 'px' : 0 }"
    :data-top="stickyTop ? (stickyTop - 1) : 0"
  >
    <div class="cart-list-filter-wrap">
      <div class="cart-list-filter j-filter-row">
        <s-checkbox-group
          v-model="checkValue"
          margin-r="0.213rem"
          class="check-groups"
        >
          <!-- all标签 -->
          <filter-check-tag
            v-if="filterLabels.length >= 1"
            :label-info="{
              tip: language.SHEIN_KEY_PC_30907,
              filterTagId: 'all',
              labelId: 'all',
              style: filterLabels[0].style
            }"
            :checked="checkValue == 'all'"
            :show-divider="false"
          />
          <filter-check-tag
            v-for="item in filterLabels"
            ref="refLabel"
            :key="item.filterTagId"
            v-tap="sendDaEvent(item, 'default_outside')"
            :label-info="item"
            :checked="checkValue == item.filterTagId"
            :show-prefix-icon="true"
            :show-suffix-icon="true"
            :show-scan-lights="showScanLights"
            :class="{ 'j-coupon-label': item?.groupClassId == 'coupon' }"
          />
          <!-- 多标签收纳情况下的外围机动坑位 -->
          <filter-check-tag
            v-if="dynamicLabel?.filterTagId"
            :label-info="dynamicLabel"
            :checked="checkValue == dynamicLabel?.filterTagId"
            prefix-icon="sui_icon_filter_16px"
            :show-prefix-icon="true"
            :show-suffix-icon="true"
            :show-divider="true"
          />
        </s-checkbox-group>
      </div>
      <!-- 多标签收纳 -->
      <MoreFilter
        v-if="['logo', 'text'].includes(filterSelectLogo) && showMoreEntry"
        :labels-in-more-pop="labelsInMorePop"
        :model-value="checkValue"
        :entry-style="filterSelectLogo"
        :filter-style="filterLabels[0].style"
        @update:model-value="updateCheckValue"
        @analysis17325="analysis17325"
        @slide-tags-change="slideTagsChange"
      />
    </div>
    <CouponTips
      v-if="selectFilterLabel?.groupClassId == 'coupon'"
      :filter-data="selectFilterLabel"
      :language="language"
    />
  </div>
  <div
    class="j-filter-placeholder"
    style="display: none"
  ></div>
</template>

<script>
export default {
  name: 'CartListFilter',
}
</script>
<script setup>
import { computed, defineEmits, ref, watch, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import FilterCheckTag from './FilterCheckTag.vue'
import MoreFilter from './MoreFilter.vue'
import CouponTips from 'public/src/pages/cart_v2/components/functional/filter-additional-info/CouponTips.vue'

const { state, getters } = useStore()

const language = computed(() => state.language)
const filterSelectLogo = computed(() => getters.filterSelectLogo)
const selectFilterLabel = computed(() => state?.filterState?.selectFilterLabel)

const props = defineProps({
  modelValue: {
    type: [Boolean, Array, String],
    default: false,
  },
  allFilterLabels: {
    type: Array,
    default: () => []
  }
})

watch(() => props.modelValue, (n, o) => {
  if (n !== o) {
    valueByLocal.value = null
    scrollToTarget()
  }
})

const emit = defineEmits(['update:modelValue'])
const valueByLocal = ref(null) // local cache, try use cache first
const checkValue = computed({
  get() {
    if (valueByLocal.value) {
      return [valueByLocal.value]
    }
    return [props.modelValue]
  },
  set(value) {
    let newValue = value[value.length - 1]
    if (newValue == props.modelValue || !newValue) newValue = 'all'
    valueByLocal.value = newValue
    emit('update:modelValue', newValue)
  }
})

let refLabel = ref(null)
// 将选中的标签自动滚动至视口中心
const scrollToTarget = async () => {
  await nextTick()
  const containerDom = document.querySelector('.j-filter-row')
  // 如果选中的是all标签，滚动到最左侧
  if (props.modelValue == 'all') {
    containerDom.scrollTo({ left: 0, behavior: 'smooth' })
    return
  }
  // 如果选中的是机动位标签，滚动到最右侧
  if (props.modelValue == dynamicLabel.value?.filterTagId) {
    containerDom.scrollTo({ left: containerDom.offsetWidth, behavior: 'smooth' })
    return
  }
  const targetIndex = props.allFilterLabels?.findIndex(v => v.filterTagId == props.modelValue)
  if (targetIndex < 0) return
  const targetDom = refLabel.value[targetIndex]?.$el
  // 获取标签容器和当前标签的位置信息
  const containerRect = containerDom.getBoundingClientRect()
  const targetRect = targetDom.getBoundingClientRect()
  // 计算滚动位置
  let scrollLeft = targetDom.offsetLeft - (containerRect.width - targetRect.width) / 2
  // 如果没有足够的滚动空间，则滚动至最右边
  if (scrollLeft + targetDom.offsetWidth > containerDom.scrollWidth) {
    scrollLeft = containerDom.scrollWidth - containerDom.offsetWidth
  }
  // 滚动至计算出的位置
  containerDom.scrollTo({ left: scrollLeft, behavior: 'smooth' })
}

let showScanLights = ref(false)
// const hasCouponCategory = computed(() => {
//   return props.allFilterLabels?.some((v) => v.groupClassId == 'coupon')
// })
// 券筛选新人指引
// const handleFilterCouponGuide = () => {
//   if (typeof window == 'undefined' || !hasCouponCategory.value) return false
//   // 检测是否有券筛选在视口中
//   const firstCouponLabelDom = document.querySelectorAll('.j-coupon-label')[0]
//   const containerDom = document.querySelectorAll('.j-cart-list-filter')?.[0]
//   const rect = firstCouponLabelDom.getBoundingClientRect()
//   // 如果视口中没有一张完整的券，执行自动滚动露出一张券
//   if (rect.right >= containerDom?.offsetWidth) {
//     firstCouponLabelDom.scrollIntoView({ behavior: 'smooth', block: 'end' })
//   }
//   // 为每一张券加扫光动画, 在进行扫光动画时，筛选栏吸顶，3s后恢复原状态
//   showScanLights.value = true
//   setTimeout(() => {
//     showScanLights.value = false
//     couponFilterGuide.end()
//   }, 3000)
//   return true
// }
// defineExpose({ handleFilterCouponGuide })

const needSplitLabel = computed(() => ['logo', 'text'].includes(filterSelectLogo.value))
const filterSplitIndex = ref(0)
const labelsInFirstView = computed(() => {
  if (!needSplitLabel.value) return []
  return props.allFilterLabels?.slice(0, filterSplitIndex.value) || []
})
const labelsInMorePop = computed(() => {
  if (!needSplitLabel.value) return []
  return props.allFilterLabels?.slice(filterSplitIndex.value) || []
})
const filterLabels = computed(() => {
  if (labelsInFirstView.value?.length) return labelsInFirstView.value
  return props.allFilterLabels
})
const showMoreEntry = computed(() => needSplitLabel.value && labelsInMorePop.value.length)

// 获取第一个不在视口中的筛选标签索引，此索引值即为首屏可显示的筛选标签个数
const findFirstIndexOutOfView = () => {
  const labels = refLabel.value
  const containerDom = document.querySelector('.j-filter-row')
  if(!containerDom) return -1
  const containerRect = containerDom.getBoundingClientRect()
  for (let i = 0; i < labels.length; i++) {
    const rect = labels[i]?.$el.getBoundingClientRect()
    if (rect.left >= containerRect.right) {
      return i
    }
  }
  return -1
}

const splitLabels = async () => {
  if (!needSplitLabel.value) return
  // 开启了多标签收纳功能后，将标签分为两组（平铺+收纳）
  await nextTick()
  if (!refLabel.value?.length) return
  const splitIndex = findFirstIndexOutOfView()
  filterSplitIndex.value = splitIndex <= -1 ? refLabel.value?.length : splitIndex
}

const slideTagsChange = (v) => {
  handleDynamicLabel(v)
}

const dynamicLabel = computed(() => labelsInMorePop.value?.find(l => dynamicValTagId.value === l.filterTagId)) || null
const dynamicValTagId = ref(null)
const handleDynamicLabel = async (val) => {
  const dynamicVal = val.label
  if (dynamicVal === 'all') return
  dynamicValTagId.value = labelsInMorePop.value?.find(l => dynamicVal === l.filterTagId)?.filterTagId
  if (dynamicValTagId.value) {
    await nextTick()
    const containerDom = document.querySelector('.j-filter-row')
    containerDom.scrollTo({ left: containerDom.offsetWidth, behavior: 'smooth' })
  }
}

/**
 * 筛选栏置顶逻辑:
 * 1. 进行筛选后，筛选栏常驻置顶，取消筛选 页面回到顶部，重新获取数据
 * 2. abt参数control_top为on，无论是否进行筛选都常驻置顶
 * 3. 滚动区域超过购物车列表，筛选栏和全场活动保持一致不吸顶
 * 4. 购物车列表数据较少，筛选栏和全场活动保持一致不吸顶
 * 1,2在当前vue文件中通过isSticky控制，3,4的滚动触发逻辑在public/src/pages/cart_v2/index.js中
 */
const isFilterControlTop = computed(() => getters.isFilterControlTop)
const isSticky = computed(() => props.modelValue !== 'all' || isFilterControlTop.value)

let stickyTop = ref(0)
let shopPromoDom = null
let resizeObserver = null

const calcStickyTop = () => {
  if (resizeObserver) return
  shopPromoDom = document.querySelector('.j-top-tpl')
  if (!shopPromoDom) return
  resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      stickyTop.value = Math.floor(entry?.contentRect?.height) || 0
    }
  })
  resizeObserver.observe(shopPromoDom)
}

const updateCheckValue = (val) => {
  checkValue.value = val
}

const analysis17325 = (labels, show_type) => {
  const label_id = labels.map((v) => v.labelId).join(',')
  daEventCenter.triggerNotice({
    daId: '1-7-3-25',
    extraData: {
      label_id,
      show_type,
    }
  })
}

const sendDaEvent = (label, showType) => {
  return {
    id: '1-7-3-26',
    data: {
      label_id: label?.labelId,
      is_cancel: label?.isSelect == '1' ? 0 : 1,
      show_type: showType,
    },
  }
}

onMounted(async() => {
  splitLabels()
  await nextTick()
  calcStickyTop()
  analysis17325(labelsInFirstView.value, 'default_outside')
})

onBeforeUnmount(() => {
  resizeObserver?.unobserve(shopPromoDom)
  resizeObserver = null
  showScanLights.value = false
})
</script>

<style lang="less" scoped>
.cart-list-filter-wrap {
  height: 48px;
  padding: 10px 0;
  background: #F4F4F4;
  margin-bottom: -10px;
  width: 100%;
  display: flex;
}
.filter-sticky {
  position: sticky;
  z-index: 2;
}
.cart-list-filter {
  flex: 1;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /*Chrome下隐藏滚动条，溢出可以透明滚动*/
  &::-webkit-scrollbar {
    display: none;
  }
  .check-groups {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    padding: 0 32/75rem;
    .S-checkbox{
      margin-right: 0.213rem;
    }
    .S-checkbox:last-child {
      margin-right: 0!important;/* stylelint-disable-line declaration-no-important */
    }
    .more-coupon {
      display: inline-flex;
      align-items: center;
      color: #2D68A8;
      font-size: 22/75rem;
    }
  }
}
.more-entry {
  width: initial;
  padding: 6/37.5rem 10/37.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: -5/37.5rem 0 10/37.5rem -5/37.5rem rgba(204, 204, 204, 0.5);
  &.acitived {
    .text-entry {
      span, .text-icon { /* stylelint-disable-line */
        color: #000 !important;/* stylelint-disable-line declaration-no-important */
      }
    }
    .logo-entry {
      border-color: #000;
    }
    .logo-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
    }
  }
  
  &.acitived.style-a_entry {
    .text-entry { /* stylelint-disable-line */
      span, .text-icon { /* stylelint-disable-line */
        color: #FA6338 !important;/* stylelint-disable-line declaration-no-important */
      }
    }

    .logo-entry { /* stylelint-disable-line */
      border-color: #FA6338;
    }
    .logo-icon { /* stylelint-disable-line */
      color: #FA6338!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.text-entry {
  display: flex;
  align-items: center;
  span {
    color: #666;
  }
  .text-icon {
    color: #bbb !important;/* stylelint-disable-line declaration-no-important */
  }
}
.logo-entry {
  padding: 0 10/37.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27/37.5rem;
  height: 27/37.5rem;
  border-radius: 50%;
  border: solid 1px #E5E5E5;
  .logo-icon {
    color: #959595!important;/* stylelint-disable-line declaration-no-important */
  }
}
.cart-list-filter {
  flex: 1;
  white-space: nowrap;
  overflow-x: auto;
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /*Chrome下隐藏滚动条，溢出可以透明滚动*/
  &::-webkit-scrollbar {
    display: none;
  }
}
.check-groups {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  padding: 0 32/75rem;
  .S-checkbox:last-child {
    margin-right: 0!important;/* stylelint-disable-line declaration-no-important */
  }
  .more-coupon {
    display: inline-flex;
    align-items: center;
    color: #2D68A8;
    font-size: 22/75rem;
  }
}
.slide-panel {
  background: #fff;
  max-height: 260/37.5rem;
  padding: 12/37.5rem; 
  overflow-y: auto;
  .all-coupon {
    color: #2D68A8;
    font-size: 12px;
    flex-shrink: 0;
  }
  .coupons-tips {
    display: flex;
    align-items: center;
    color: #979797;
    .tips-text {
      margin: 0 4/37.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.category-filter {
  &:not(:last-child) {
    margin-bottom: 12/37.5rem;
  }
  .category-title {
    display: flex;
    justify-content: space-between;
    height: 14px;
    line-height: 14px;
    .text {
      color: #222;
      font-size: 12px;
      font-weight: bold;
      margin-right: 16/37.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .check-tag {
    margin-top: 8/37.5rem;
    margin-right: 8/37.5rem !important;/* stylelint-disable-line declaration-no-important */
  }
}
</style>
