<template>
  <div
    v-if="type == 'banner' && promotionTip"
    v-expose="sendExposeHeaderEvent()"
    class="cart_item-header without-promoGoods promo-group"
    :class="[`j-promotion-header-${promotionInfo.data.promotion_id}`]"
    :type-id="promotionInfo.data.type_id"
    :style="groupHeadStyle"
  >
    <!-- 横幅类型 -->
    <slot></slot>
    <img
      v-if="groupHeadStyleType == 'withImage'"
      class="promotion-header-imageStyle"
      :src="groupHeadImageInfo?.url"
      :style="{
        width: `${(+groupHeadImageInfo?.width / 3)}px`,
        height: `${(+groupHeadImageInfo?.height / 3)}px`,
      }"
    />
    <section
      v-else-if="promotionTip"
      v-enterkey
      class="cart_item-banner"
      :class="{ 
        clickable: (action == 1) && !isBatchActivePage,
      }"
      tabindex="0"
      role="button"
      @click="clickPick"
    >
      <p
        class="cart_item-tip"
        :class="{'cart-red-dark': changeColor}"
      >
        <span v-html="promotionTip"></span>
        <ClientOnly>
          <count-down-time
            v-if="promotionInfo.data.is_count_down == 1"
            :time-stamp="promotionInfo.data.end_time"
          />
        </ClientOnly>
      </p>
      <sui_icon_more_right_14px
        v-if="(action == 1) && !isBatchActivePage && promotionInfo.data.type_id != 11"
        size="16px"
        :is-rotate="locals.GB_cssRight"
      />
    </section>
  </div>
  <div
    v-else-if="promotionTip"
    v-expose="analysisData('expose_cartpromotionadd.page_cart')"
    class="cart_item-header promo-group cart_item-header__gift"
    :class="`j-promotion-header-${promotionInfo.data.promotion_id}`"
    :type-id="promotionInfo.data.type_id"
  >
    <!-- 标题 + 提示语 -->
    <div class="header-content">
      <slot></slot>
      <section class="cart-item__header-section">
        <div style="width: 100%">
          <div
            class="cart_item-title"
            tabindex="0"
            :aria-label="promotionTitle + ',' + promotionTip"
          >
            <span>{{ promotionTitle }}</span>
          </div>
          <p
            class="cart_item-desc"
            v-html="promotionTip"
          >
          </p>
          <ClientOnly>
            <count-down-time
              v-if="promotionInfo.data.is_count_down == 1"
              class="c-count__down-time"
              :time-stamp="promotionInfo.data.end_time"
              show-type="inline-promotion"
              :show-end-tips="true"
            />
          </ClientOnly>
        </div>
        <template v-if="action > 0 && !isBatchActivePage">
          <s-button
            v-enterkey
            :type="['primary','H28PX']"
            @click="clickPick"
          >
            {{ handleText }}
          </s-button>
        </template>
      </section>
    </div>
    <GiftList
      v-if="showFullGiftsList"
      :gift-list="giftList"
      :promotion-id="promotionInfo.data.promotion_id"
      :promotion-type="promotionInfo.data.type_id"
      :type="1"
      @handle-view-all="clickPick(false)"
    />
  </div>
</template>
<script>
export default {
  name: 'PromotionHeader',
}
</script>
<script setup>
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import GiftList from 'public/src/pages/cart_v2/components/functional/gift-list/GiftList.vue'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'
import { BRAND_PRODUCT_PROMOTION_TYPE_IDS, TITLE_PROMOTION_TYPE_IDS } from 'public/src/pages/cart_v2/common/constants.js'
import { useStore } from 'vuex'
import { computed, defineProps } from 'vue'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const { state, commit } = useStore()
const AddItem = useCartAddItem()

const props = defineProps({
  promotionInfo: {
    type: Object,
    default: () => ({})
  },
  isBatchActivePage: {
    type: Boolean,
    default: false
  }
})

const language = computed(() => state.language)
const locals = computed(() => state.locals)

const promotionTip = computed(() => {
  return props.promotionInfo?.promotionTips?.html_text || ''
})

const isPicked = computed(() => {
  return props.promotionInfo?.data?.isPicked == 1
})

const giftList = computed(() => {
  return props.promotionInfo.data?.promotionGoods || []
})

const showFullGiftsList = computed(() => {
  return !isPicked.value && giftList.value.length && !props.isBatchActivePage
})

const type = computed(() => {
  const typeId = props.promotionInfo.data?.type_id
  const BannerStyleTypeIds = [1, 9, 10, 11, 14, 15, 16, 17, 18, 19, 24, 25, 31] // 横幅类型的促销活动
  let result = 'common'
  if (BannerStyleTypeIds.includes(Number(typeId))) result = 'banner'
  return result
})

// 0: 无操作; 1: Add，可以添加商品; 2: Pick，可以选择商品
const  action = computed(() =>{
  let actionOpt = 0
  const { type_id, range, next, isMeet, promotion_logo_type, isMax  } = props.promotionInfo.data || {}
  if (type_id == 1) {
    actionOpt = 1
  }
  if ([2, 13, 28].includes(+type_id)) {
    actionOpt = isMeet == 1 ? 2 : 1
  }
  if ([14, 15, 20, 21, 25].includes(+type_id)) {
    actionOpt = range <= 0 ? 1 : next != '0' ? 1 : 0
  }
  if (type_id == '9') {
    if (promotion_logo_type == '19') {
      actionOpt = isMax == '1' ? 0 : 1
    } else {
      actionOpt = range <= 0 ? 1 : next != '0' ? 1 : 0
    }
  }
  return actionOpt
})

const promotionTitle = computed(() => {
  let title = ''
  let { type_id, brandName = '', mainProductRange } = props.promotionInfo.data || {}
  for(let key in TITLE_PROMOTION_TYPE_IDS){
    if(TITLE_PROMOTION_TYPE_IDS[key].indexOf(+props.promotionInfo.data?.type_id) > -1){
      title = `${(mainProductRange == 4 && BRAND_PRODUCT_PROMOTION_TYPE_IDS.includes(+type_id)) ? brandName + ' ' : '' }${language.value[key] || ''}`
    }
  }
  return title
})

const handleText = computed(() => {
  if (action.value == 1) {
    return language.value.SHEIN_KEY_PC_15215
  } else if (action.value == 2) {
    if (props.promotionInfo.data?.isPicked == '1') {
      return language.value.SHEIN_KEY_PC_15969
    } else {
      return language.value.SHEIN_KEY_PC_15968
    }
  } else {
    return ''
  }
})

const changeColor = computed(() => {
  return props.promotionInfo.data?.overLimit == '1'
})

const groupHeadStyle = computed(() => {
  if (groupHeadStyleType.value == 'withImage') {
    return {
      'background': '#fff'
    }
  }
  return {}
})

/**
 * 组头样式，返回withImage表示带图片样式，返回空或normal表示线上样式
 */
const groupHeadStyleType = computed(() => {
  return props.promotionInfo?.promotionTips?.style || ''
})

/**
 * 组头样式为withImage时，返回的图片内容和宽高等信息
 */
const groupHeadImageInfo = computed(() => {
  return props.promotionInfo?.promotionTips?.imageInfo || {}
})

// 是否为百亿补贴商品组
const isBrandDealsGroup = computed(() => {
  const { type_id = '', promotion_logo_type = '' } = props.promotionInfo?.data || {}
  return type_id == '31' && promotion_logo_type == '21'
})

const sendExposeHeaderEvent = () => {
  if (groupHeadStyleType.value === 'withImage' && isBrandDealsGroup.value) {
    return { id: 'expose_brand_deals.page_cart' }
  } else {
    return analysisData('expose_cartpromotionadd.page_cart')
  }
}

const analysisData = (id) => {
  let { promotion_id, mainProductRange, is_shop_group, store_id = '-' } = props.promotionInfo.data
  const { isFullPromotion, typeId, is_couponbag } = state.promotionState.cartPromotionAnalysis?.[promotion_id] || {}
  return {
    id,
    data: {
      typeId,
      isFullPromotion: isFullPromotion || 0,
      is_brand_product: mainProductRange == 4 ? 1 : 0,
      is_couponbag: is_couponbag,
      is_member_gift: 0,
      is_all_store: is_shop_group == 1 ? 1 : 0,
      store_id: store_id,
      activity_id: promotion_id,
    }
  }
}

const clickPick = async (isAnalysis = true) => {
  if (props.isBatchActivePage) return
  const { promotion_id: promotionId, type_id: typeId, sc_id, popupInfoId, promotionJumpLink = ''  } = props.promotionInfo.data

  const addGoodToReach = sc_id && action.value == 1
  const buyGiftPick = typeId == 2 && (action.value == 2)
  const addBuyPick = typeId == 13 && (action.value == 2)
  const partFullGifts = typeId == 28 && (action.value == 2)

  if (isAnalysis) {
    daEventCenter.triggerNotice(analysisData('click_cartpromotionadd.page_cart'))
  }
  let type
  if (buyGiftPick) type = 'buy-gift' // 买赠
  if (addBuyPick) type = 'add-buy' // 加价购
  if (partFullGifts) type = 'full-gift' // 部分满赠
    
  if (type) {
    commit('changeAppendageDrawerStatus', { promotionId })
  } else if (addGoodToReach) {

    if([9, 25, 20, 1, 15, 14].includes(+typeId) || ([13, 2, 28].includes(+typeId) && action.value == 1)) {
      AddItem.show({
        popupInfoId,
        state: 'promotion_add',
      })
      return 
    }

    if (promotionJumpLink) {
      const newWin = window.open('', '_self')
      newWin.location = `${gbCommonInfo.langPath}${promotionJumpLink}`
    } 
  }
}
</script>
  <style lang="less">
  .promo-group{
    display: flex;
    align-items: center;
    .cart_item-banner:not(.mini-cart){
      font-size: 16px;
      color: #000000;
      font-weight: bold;
    }
    .cart_item-banner,.cart-item__header-section{
      flex: 1;
      background: @sui_color_promo_bg;
      padding: 16px;
      .flexbox();
      .space-between();
      .cart_item-title {
        .flexbox();
        .space-between();
      }
      .cart_item-action {
        .padding-l(10px);
        position: static;
        white-space: nowrap;
      }
      &.clickable {
        cursor: pointer;
      }
    }
    .cart-red-dark {
      color: @color_red_dark;
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  .cart_item-header__gift {
    display: block;
    width: 100%;
    .header-content {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  </style>
  
