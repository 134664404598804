/**
 * @file monitor.js
 * 商品选购公共的部分可在public/src/pages/common/business-monitor/common.js添加
 * metricPageSuccess页面核心接口是否正常响应、metricCccClick组件点击
 * --------
 * 首页导航独有的客户端监控模块可在此文件添加
 */

import { SIMetric } from 'public/src/pages/common/monitor/index'
import { isSpider, isString } from '@shein/common-function'

// params: { metric_name: '', tags: { page: '', status: 1|0 }, message: '' }

/**
 * 组件过滤
 * tags.page：页面名称
 * tags.component_type：过滤的组件类型style
 * tags.position: 组件的位置
 * tags.reason: 过滤原因
 *  */ 
const metricCompFilter = (params = {}) => {
  if(params && typeof params === 'object') {
    params.params = {
      ...(params.params || {}),
      isSpider: typeof window === 'undefined' ? 'unknown' : isSpider()
    }
  }

  SIMetric.metricCount({ metric_name: 'ccc_component_be_filtered_total', ...params })
}

/**
 * 分类页类目点击
 * tags.page：页面名称
 * tags.cate_level: 左边一级分类报1 右边二级分类报 2
 *  */ 
const metricCategoryClick = (params = {}) => {
  if(params && typeof params === 'object') {
    params.params = {
      ...(params.params || {}),
      isSpider: typeof window === 'undefined' ? 'unknown' : isSpider()
    }
  }

  SIMetric.metricCount({ metric_name: 'category_click_total', ...params })
}

const metricCategoryType = (params = {}) => {
  if(params && typeof params === 'object') {
    params.params = {
      ...(params.params || {}),
      isSpider: typeof window === 'undefined' ? 'unknown' : isSpider()
    }
  }

  SIMetric.metricCount({ metric_name: 'category_click_type_total', ...params })
}

/**
 * 全局弹窗队列监控埋点
 * tags.track_type：埋点类型(dq_dialog_add:2 | dq_dialog_show:3 | dq_dialog_close:4)
 * tags.alert_type：弹窗类型名
 *  */ 
const metricGlobPopup = (params = {}) => {
  if(params && typeof params === 'object') {
    params.params = {
      ...(params.params || {}),
      isSpider: typeof window === 'undefined' ? 'unknown' : isSpider()
    }
  }

  SIMetric.metricCount({ metric_name: 'home_alert_queue_track_total', ...params })
}


/**
 * 监控首页导航客户端warn日志的统计数
 * tags.message(string)
 *  */ 
const metricWarnSlsCount = (params = {}) => {
  if(params && typeof params === 'object') {
    params.params = {
      ...(params.params || {}),
      isSpider: typeof window === 'undefined' ? 'unknown' : isSpider()
    }
  }
  SIMetric.metricCount({ metric_name: 'web_client_home_warn_total', ...params })
}

/**
 * 监控首页导航客户端error日志的统计数
 * tags.message(string)
 *  */ 
const metricErrorSlsCount = (params = {}) => {
  if(params && typeof params === 'object') {
    params.params = {
      ...(params.params || {}),
      isSpider: typeof window === 'undefined' ? 'unknown' : isSpider()
    }
  }
  SIMetric.metricCount({ metric_name: 'web_client_home_error_total', ...params })
}

/**
 * CCC组件曝光-目前仅首页用，其他业务线要用一定要去重上报。
 * tags.page：页面名称
 * tags.business_block: 板块，比如沉浸式和单图都是h1
 * tags.style_nm：组件类型styletype
 * tags.tab_nm: 频道名，比如all、women
 * tags.position: 组件的位置(从1开始 与埋点一致)
 *  */ 
const metricCccExpose = (tags) => {
  const { page, business_block, style_nm, tab_nm, position } = tags || {}
  if (!isString(page) || !isString(business_block) || !isString(style_nm) || !isString(tab_nm)) return console.warn('page or business_block or style_nm or tab_nm is not string')
  
  // const loginfo = `page:${page} business_block:${business_block} style_nm:${style_nm} tab_nm:${tab_nm} position:${position}`
  // console.log(
  //   `%c 上报曝光组件1次成功：${loginfo}`,
  //   'background: #9370DB; color: white; padding: 4px; border-radius: 4px;',
  // )
  SIMetric.metricCount({ 
    metric_name: 'web_ccc_block_main_expose_total', 
    tags, 
    message: `The ${tab_nm} channel ${business_block} ${style_nm} components expose on the ${position} position of ${page}`
  })
}


/**
 * 首页接口返回组件上报
 * tags.page：页面名称
 * tags.business_block: 板块，比如沉浸式和单图都是h1
 * tags.style_nm：组件类型styletype
 * tags.tab_nm: 频道名，比如all、women
 *  */ 
const metricCompListByInterface = ({ page = '', contextData = {}, extraData = {} } = {}) => {

  const tab_nm = extraData?.channel_id || ''
  const content = contextData?.content || []

  if(!page || !content?.length || typeof window === 'undefined' || !tab_nm) {
    console.warn('Missing parameters by metricCompListByInterface')
    return
  }
  content?.forEach(element => {
    let business_block = element?.businessBlock
    const style_nm = element?.styleType
    if(!business_block || !style_nm) return
    // const loginfo = `page:${page} business_block:${business_block} style_nm:${style_nm} tab_nm:${tab_nm}`
    // console.log(
    //   `%c 接口：${loginfo}`,
    //   'background: #D2B48C; color: white; padding: 4px; border-radius: 4px;',
    // )
    SIMetric.metricCount({ 
      metric_name: 'web_ccc_interface_comp_total', 
      tags: {
        page,
        business_block,
        style_nm,
        tab_nm: String(tab_nm),
      },
    })
  })
}

export default {
  metricCccExpose,
  metricCompFilter,
  metricCategoryClick,
  metricCategoryType,
  metricGlobPopup,
  metricWarnSlsCount,
  metricErrorSlsCount,
  metricCompListByInterface,
}
