<template>
  <div
    v-expose="analysisData('expose_cartpromotionadd.page_cart')"
    class="cart_item-header"
    :class="{'not-promoGoods':(!isPicked || showThreeStore) && isSingleMall}"
  >
    <!-- 标题 -->
    <section
      class="cart-item__header-section"
      :class="{'platform-header-section': !isSingleMall}"
    >
      <div class="section-content">
        <sui_icon_gift_16px
          v-if="!isSingleMall"
          class="pro-tag"
          size="16px"
        />
        <div>
          <p
            v-if="isSingleMall"
            class="cart_item-title full-title"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PC_16033 + promotionTip"
          >
            {{ language.SHEIN_KEY_PC_16033 }}
            <ClientOnly>
              <count-down-time
                v-if="promotionInfo.data.is_count_down && promotionInfo.data.end_time"
                class="c-count__down-time"
                :time-stamp="promotionInfo.data.end_time"
                show-type="inline-promotion"
                :show-end-tips="true"
              />
            </ClientOnly>
          </p>
          <!-- 提示 -->
          <p
            class="cart_item-desc"
            v-html="promotionTip"
          >
          </p>
          <ClientOnly>
            <count-down-time
              v-if="promotionInfo.data.is_count_down && promotionInfo.data.end_time && !isSingleMall"
              class="c-count__down-time"
              :time-stamp="promotionInfo.data.end_time"
            />
          </ClientOnly>
        </div>
      </div>
      <!-- 点击按钮 -->
      <s-button
        v-if="isSingleMall"
        v-enterkey
        :type="['primary','H28PX']"
        @click="handleFullGift"
      >
        {{ actionText }}
      </s-button>
      <a
        v-else
        class="operation"
        tabindex="0"
        role="button"
        @click="handleFullGift"
      >
        {{ actionText }}
        <sui_icon_more_right_12px
          :is-rotate="locals.GB_cssRight"
          size="12px"
        />
      </a>
    </section>
    <div class="gift-list-area">
      <GiftList
        v-if="showFullGiftsList"
        :gift-list="giftList"
        :promotion-id="promotionInfo.data.promotion_id"
        :promotion-type="promotionInfo.data.type_id"
        @handle-view-all="handleFullGift(false)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullGiftHeader',
}
</script>
<script setup>
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import GiftList from 'public/src/pages/cart_v2/components/functional/gift-list/GiftList.vue'
import { sui_icon_more_right_12px, sui_icon_gift_16px } from '@shein-aidc/icon-vue3'
import { useStore } from 'vuex'
import { computed, defineProps } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const AddItem = useCartAddItem()

const { state, getters, commit } = useStore()

const props = defineProps({
  isSingleMall: {     // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    type: Boolean,
    default: true
  },
  promotionInfo: {
    type: Object,
    default: () => ({})
  },
})

const language = computed(() => state.language)
const locals = computed(() => state.locals)
const showThreeStore = computed(() => getters.showThreeStore)
const promotionTip = computed(() => {
  return props.promotionInfo?.promotionTips?.html_text || ''
})
const showAdd = computed(() => {
  return props.promotionInfo && props.promotionInfo.data?.isMeet != 1
})

const isPicked = computed(() => {
  return props.promotionInfo && props.promotionInfo.data?.isPicked == 1
})

const actionText = computed(() => {
  return showAdd.value ? language.value.SHEIN_KEY_PC_15215 : isPicked.value ? language.value.SHEIN_KEY_PC_15969 : language.value.SHEIN_KEY_PC_15968
})

const giftList = computed(() => {
  return props.promotionInfo.data?.promotionGoods || []
})

const showFullGiftsList = computed(() => {
  return !isPicked.value && giftList.value.length
})

const isSheinClubPromotion = computed(() => {
  return [8, 14].includes(+props.promotionInfo.data?.promotion_logo_type)
})

const handleFullGift = (isAnalysis = true) => {
  if(isAnalysis) {
    daEventCenter.triggerNotice(analysisData('click_cartpromotionadd.page_cart'))
  }
  if(isAnalysis && showAdd.value) {
    AddItem.show({
      popupInfoId: props.promotionInfo.data?.popupInfoId,
      state: 'promotion_add',
    })
    return
  }
  commit('changeAppendageDrawerStatus', { promotionId: props.promotionInfo.data?.promotion_id })
}

const analysisData = (id) => {
  let { promotion_id } = props.promotionInfo.data
  const { isFullPromotion, typeId } = state.promotionState.cartPromotionAnalysis?.[promotion_id] || {}
  return {
    id,
    data: {
      id: promotion_id,
      typeId: typeId,
      isFullPromotion: isFullPromotion,
      is_member_gift: +isSheinClubPromotion.value,
      activity_id: promotion_id,
    }
  }
}
</script>

<style lang="less">
.section-content{
  display: flex;
  align-items: center;
  .pro-tag{
    margin-right: 8px;
  }
}
.cart_item-header {
  background: @sui_color_promo_bg;
  .gift-list-area {
    background: @sui_color_promo_bg;
    padding: 0 12px;
  }
}
</style>
