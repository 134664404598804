<template>
  <div
    ref="el"
    class="cart-item__wrapper"
  >
    <div class="cart-item__checkbox">
      <slot></slot>
    </div>
    <div class="cart-item__content">
      <BMainCartItem
        :data="item"
        :language="language"
        :constant="constant"
        :use="use"
        :config="config"
        @expose="handleItemExpose(item)"
      /> 
    </div>
  </div>
</template>

<script setup>
/**
 * 主车商品行组件
 * @warning 用于批量操作页面、主车可售商品行、主车售罄商品行（弹窗），其他地方切勿复用
 */
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { BMainCartItem, getConstant } from '@shein-aidc/bs-cart-item-laptop'
import { SMessage } from '@shein-aidc/sui-message'
import { useItemEvents } from 'public/src/pages/cart_v2/hooks/item/index'
import { animationDelete } from 'public/src/pages/cart_v2/utils/index'
import { useGoodsJump } from 'public/src/pages/cart_v2/hooks/useGoodsJump'
import CustomContentDialog from 'public/src/pages/cart_v2/components/business/custom-content-dialog'
import { expose_goods_list, getSaGoodsList } from 'public/src/pages/cart_v2/common/analysis/item/index.js'
import { expose_delete_tips } from 'public/src/pages/cart_v2/common/analysis/deleteDialog'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0
  },
  isBatchActivePage: {
    type: Boolean,
    default: false,
  },
  isShowPromotionTag: {
    type: Boolean,
    default: true,
  }
})
const { state, commit, dispatch } = useStore()
const { jumpDetail } = useGoodsJump()
const el = ref(null)
const cartAbtInfo = computed(() => state.cartAbtInfo)
const labelId = computed(() => state.filterState?.selectFilterLabel?.labelId ?? '')
const constant = ref(getConstant())
const isShowNewDelete = computed(() => props.item?.aggregateProductBusiness?.singleDeleteStayPopUp?.stayType != '0' && props.item?.aggregateProductBusiness?.cartItemStyleType == '1')
const batchActive = computed(() => props.isBatchActivePage)
const language = computed(() => state.language)
const use = computed(() => {
  const defaultUse = [
    'GoodsImg',
    'GoodsTitle',
    'GoodsStore',
    'GoodsSeriesFlag',
    'GoodsDiscountFlag',
    'GoodsCustomContent',
    'GoodsSaleAttr',
    'GoodsAlert',
    'BehaviorLabel',
    'ReducePriceHint',
    'GoodsQty',
    'GoodsBelt',
    'GoodsPrice',
    'EstimatedPrice',
    'DiscountNormalTag',
    'DiscountLimitTag',
    'GoodsTitleIcon',
    'FindSimilarText',
    'ChangeMall',
    'BrandDealsTag'
  ]

  if (!props.isBatchActivePage) {
    defaultUse.push(...[
      'GoodsDeleteButton',
      'GoodsAddWish',
      'FindSimilarIcon',
      'GoodsNegativeInfo',
    ])
  }

  if (props.isShowPromotionTag) {
    defaultUse.push(...[
      'SFSTag',
      'VoucherTag',
      'EvoluTag',
      'PromotionTag',
      'InsurePriceTag',
      'QuickShipTag',
      'FreeShippingTag',
      'HolidayDeliveryTag',
      'RankListTag',
    ])
  }

  return defaultUse
})

const {
  goodsQtyEvent,
  goodsSaleAttrEvent,
  goodsAddWishEvent,
  findSimilarEvent,
  goodsDeleteButtonEvent,
  discountLimitTagEvent,
  discountNormalTagEvent,
  estimatedPriceEvent,
  promotionTagEvent,
  insurePriceTagEvent,
  goodsStoreEvent,
  behaviorLabelEvent,
} = useItemEvents()
const config = ref({
  GoodsStore: {
    events: {
      onExpose: (itemOpts) => {
        goodsStoreEvent.onExpose({ itemOpts })
      },
      onReport: (itemOpts) => {
        goodsStoreEvent.onReport({ itemOpts })
      },
      onClick: (itemOpts) => {
        goodsStoreEvent.onClick({ itemOpts })
      }
    }
  },
  GoodsDeleteButton: {
    type: isShowNewDelete.value ? 'v2' : 'v1',
    events: {
      onClick(itemOpts) {
        if (isShowNewDelete.value) {
          const events = {
            onDeleteSuccess,
            onM2WSuccess,
          }
          goodsDeleteButtonEvent.onClick({
            itemOpts,
            options: { events, isShowOverLimitToast: true }
          })
        } else {
          expose_delete_tips(itemOpts.data.value)
        }
      },
      onButtonClick(itemOpts) {
        const events = {
          onDeleteSuccess,
        }
        goodsDeleteButtonEvent.onButtonClick({
          itemOpts,
          options: { events, isShowOverLimitToast: true }
        })
      }
    }
  },
  GoodsAddWish: {
    events: {
      onReport(itemOpts) {
        goodsAddWishEvent.onReport({ itemOpts })
      },
      onButtonClick: (itemOpts) => {
        const events = {
          onSuccess: onM2WSuccess
        }
        const analysis = {
          click_add_collect: (defaultData) => {
            defaultData.postData.index = props.index
            return defaultData
          }
        }
        goodsAddWishEvent.onButtonClick({ itemOpts, options: { events, isShowOverLimitToast: true }, analysis })
      }
    }
  },
  GoodsImg: {
    props() {
      // 千万不要尝试把代码优化成下面这种方式，会覆盖原子组件默认的 isClick 逻辑
      // return {
      //   isClick: props.isBatchActivePage ? false : true,
      //   isLazyLoad: props.index >= 6,
      // }
      if (props.isBatchActivePage) {
        return {
          isLazyLoad: props.index >= 6,
          isClick: false,
        }
      }
      return {
        isLazyLoad: props.index >= 6,
        isFspElement: props.index <= 2
      }
    },
    events: {
      onClick(itemOpts) {
        const analysis = {
          click_goods_list(defaultData) {
            return {
              ...defaultData,
              index: props.index,
            }
          }
        }
        jumpDetail({ itemOpts, analysis })
      }
    }
  },
  GoodsSeriesFlag: {
    props() {
      return {
        isLazyLoad: props.index >= 6,
      }
    },
  },
  GoodsTitle: {
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const analysis = {
          click_goods_list(defaultData) {
            return {
              ...defaultData,
              index: props.index,
            }
          }
        }
        jumpDetail({ itemOpts: { data }, analysis })
      }
    }
  },
  GoodsQty: {
    props() {
      if (batchActive.value) {
        return {
          isClick: false,
          isShowMask: true,
        }
      }
    },
    events: {
      onChange(itemOpts) {
        goodsQtyEvent.onChange({ itemOpts, options: { isShowOverLimitToast: true } })
      },
      onReport(itemOpts) {
        goodsQtyEvent.onReport({ itemOpts })
      },
      onExpose(itemOpts) {
        goodsQtyEvent.onExpose({ itemOpts })
      },
    }
  },
  GoodsSaleAttr: {
    props({ data }) {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
      return {
        loading: data.value.isOpenQuickView
      }
    },
    events: {
      onClick(itemOpts) {
        goodsSaleAttrEvent.onClick({ itemOpts, options: { index: props.index + 1 } })
      }
    }
  },
  FindSimilarIcon: {
    events: {
      onExpose(itemOpts) {
        findSimilarEvent.onExpose({
          itemOpts,
          analysis: {
            expose_findsimilar: () => null,
            expose_findsimilar_button: () => null,
          } })
      },
      onReport(itemOpts) {
        findSimilarEvent.onReport({
          itemOpts,
          analysis: {
            click_findsimilar: () => null,
            click_findsimilar_button: () => null,
          } })
      },
      onClick(itemOpts) {
        findSimilarEvent.onClick({ itemOpts })
      }
    }
  },
  FindSimilarText: {
    props() {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onExpose(itemOpts) {
        findSimilarEvent.onExpose({
          itemOpts,
          analysis: {
            expose_left_findsimilar: () => null,
            click_findsimilar_button: () => null,
          } })
      },
      onReport(itemOpts) {
        findSimilarEvent.onReport({
          itemOpts,
          analysis: {
            click_left_findsimilar: () => null,
            click_findsimilar_button: () => null,
          } })
      },
      onClick(itemOpts) {
        findSimilarEvent.onClick({
          itemOpts,
          options: {
            similarPopoverConfig: {
              similarFrom: 'out_of_stock',
            }
          }
        })
      }
    }
  },
  ChangeMall: {
    props() {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onExpose({ data }) {
        daEventCenter.triggerNotice({
          daId: '1-7-3-65',
          bindData: [
            {
              sku_code: data.value.product.sku_code
            }
          ]
        })
      },
      async onClick({ data }) {
        daEventCenter.triggerNotice({
          daId: '1-7-3-66',
          extraData: {
            sku_code: data.value.product.sku_code 
          }
        })
        if (batchActive.value) return
        const reqParams = {
          is_checked: data.value.is_checked,
          append_id_list: data.value.appendIds,
          sku_code: data.value.product?.sku_code,
          id: data.value.id,
          mall_code: data.value.aggregateProductBusiness?.changeMallInfo?.lastMallCode,
          quantity: data.value.quantity,
          goods_id: data.value.product?.goods_id,
        }
        commit('updateState', { key: 'loadingShow', value: true })
        try {
          const res = await dispatch('fetchCartUpdateAttr', reqParams)
          if (res.code == '0') {
            dispatch('showOverLimitToast', { id: data.value.id, isShowOverLimitToast: true })
            GB_analysis_obj.editUpdate(data.value, res)
          } else {
            SMessage({
              message: res.msg,
              type: 'warning',
              offset: 180,
            })
          }
        } finally {
          commit('updateState', { key: 'loadingShow', value: false })
        }
      }
    }
  },
  BehaviorLabel: {
    events: {
      onLabelExpose(itemOpts) {
        behaviorLabelEvent.onLabelExpose({ itemOpts }) 
      }
    }
  },
  PromotionTag: {
    props() {
      if (batchActive.value) {
        return {
          isClick: false,
        }
      }
    },
    events: {
      onClick(itemOpts) {
        promotionTagEvent.onClick({
          itemOpts,
          options: { index: props.index + 1 }
        })
      },
      onReport(itemOpts) {
        promotionTagEvent.onReport({
          itemOpts,
          options: { index: props.index + 1 }
        })
      },
      onExpose(itemOpts) {
        promotionTagEvent.onExpose({
          itemOpts,
          options: { index: props.index + 1 }
        })
      },
    }
  },
  RankListTag: {
    events: {

    }
  },
  InsurePriceTag: {
    events: {
      onExpose(itemOpts) {
        insurePriceTagEvent.onExpose({
          itemOpts,
        })
      },
    }
  },
  DisdountLimitTag: {
    props() {
      if (batchActive.value) {
        return {
          isHover: false,
        }
      }
    },
    events: {
      onOpened: (itemOpts) => {
        const analysis = {
          click_discount_details(defaultData) {
            return {
              ...defaultData,
              result_order: props.index + 1,
            }
          }
        }
        discountLimitTagEvent.onOpened({ itemOpts, analysis })  
      }
    }
  },
  DiscountNormalTag: {
    props() {
      if (batchActive.value) {
        return {
          isHover: false,
        }
      }
    },
    events: {
      onOpened: (itemOpts) => {
        const analysis = {
          click_discount_details(defaultData) {
            return {
              ...defaultData,
              result_order: props.index + 1,
            }
          }
        }
        discountNormalTagEvent.onOpened({ itemOpts, analysis })
      }
    }
  },
  EstimatedPrice: {
    props() {
      if (batchActive.value) {
        return {
          isHover: false,
        }
      }
    },
    events: {
      onOpened: (itemOpts) => {
        estimatedPriceEvent.onOpened({ itemOpts })
      },
      onExposeChange: (itemOpts) => {
        estimatedPriceEvent.onExposeChange({ itemOpts })
      }
    }
  },
  GoodsCustomContent: {
    events: {
      onClick: (itemOpts) => {
        CustomContentDialog.open({
          data: itemOpts.event,
        })
      }
    }
  }
})

const onDeleteSuccess = async () => {
  await animationDelete(el.value)
}
const onM2WSuccess = async () => {
  await animationDelete(el.value)
}

const handleItemExpose = (item) => {
  if (batchActive.value) return
  expose_goods_list(item, (defaultData) => {
    const data = {
      ...defaultData,
      label_id: labelId.value,
      goods_list: getSaGoodsList(item, { index: props.index, cartAbtInfo: cartAbtInfo.value, scene: 'cart', el: el.value }),
      src_module: 'trade_display',
      ranking_list_identifier: item?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.rankListIdentifier,
      board_generate_type: item?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.boardGenerateType,
    }
    return data
  })
}
</script>

<style lang="less">
.cart-item {
  &__wrapper {
    display: flex;
    align-items: flex-start;
  }
  &__checkbox {
    display: flex;
    align-items: center;
    height: 120px;
    flex-shrink: 0;
  }
  &__content {
    flex: 1;
  }
}
</style>
