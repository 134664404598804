import { useStore } from 'vuex'
import { computed } from 'vue'
import { sumBy } from 'lodash'
import AddOn from 'public/src/pages/cart_v2/components/business/add-on/index.js'
// import MockJson from '/Users/10073429/Documents/mock/json/2024-11-04/cart10.json'
import jump2Addon from 'public/src/pages/cart_v2/components/business/add-on/jump2Addon.js'

const useCartAddItem = () => {
  const cartStore = useStore()
  const recommenQueryInfo = computed(() => {
    const products = cartStore?.state?.carts?.map((item) => item.product) || []
    const limit = cartStore?.state?.cartAbtInfo?.Quickshiprecommendthreshold?.param?.quickship_recommend_threshold || 0
    const sum = sumBy(products.filter(item => Boolean(item.is_checked) && item.quick_ship == 1), item => +(item.unitPrice?.usdAmount || 0))

    return {
      sceneId: 152,
      goodsIds: products.map((v) => v.goods_id),
      cateIds: products.map((v) => v.cat_id),
      quickship_prior: sum > limit ? 1 : 0,
      mallCode: cartStore?.getters?.singleMallCode,
    }
  })
  const getFreeShipItem = (popupInfoId) => cartStore?.state?.promotionState?.cartPopUpInfoData?.shippingActivityPopUpData?.find?.(item => item?.popupInfoId === popupInfoId) || cartStore?.state?.promotionState?.cartPopUpInfoData?.shippingActivityPopUpData?.[0]
  // 交易场景以下场景可跳转商详
  // 购物车券助手、购物车免邮活动、购物车促销活动
  const canToDetail = (state) => {
    return ['shipping_add', 'promotion_add', 'coupon_helper_add'].includes(state) && cartStore?.getters?.cartAddTradeGoodsDetailSwitch
  }
  const getAddOnPropsFromCart = ({ popupInfoId, state }) => {
    if (state === 'promotion_add') {
      const promoItem = cartStore?.state?.promotionState?.cartPopUpInfoData?.promotionPopUpData?.find?.(item => item?.data?.promotionPopupInfo?.popupInfoId === popupInfoId)
      if (!promoItem) return null
      return AddOn.Helper.getPromotionProps({
        promotion: promoItem?.data,
        config: {
          isClickToDetail: canToDetail(state)
        },
      })
    }
    if (state === 'shipping_add') {
      const freeShipItem = getFreeShipItem(popupInfoId)
      if (!freeShipItem) return null
      return AddOn.Helper.getFreeShipProps({
        promotion: freeShipItem,
        queryInfo: {
          storeCode: freeShipItem.storeCode || '',
          mallCode: freeShipItem.mallCode || '',
          freeShippingScope: freeShipItem.freeShippingScope || '',
        },
        config: {
          isClickToDetail: canToDetail(state)
        },
      })
    }
    return null
  }
  const handleOpenLogin = ({ addWishFn }) => {
    const free_shipping = cartStore?.state?.carts?.length && !!cartStore?.state?.checkoutState?.checkoutLabelList?.find(item => item.type == 1)?.tip 
    const promotion_price = cartStore?.state?.cartInfo?.mallCartPriceData?.savedPrice?.price?.amountWithSymbol
    SHEIN_LOGIN?.show({
      cb: () => {
        addWishFn?.()
      },
      from: 'wishlist',
      retainData: {
        promotion_price,
        // 空购物车 不免邮
        free_shipping,
      },
    })
  }

  // 券凑单
  const handleCoupon = (opts) => {
    let isCartUpdated = false
    const { coupon, secondaryCoupon, state, config } = opts
    const couponProps = AddOn.Helper.getCouponProps({
      coupon,
      secondaryCoupon,
      config: {
        ...config,
        isClickToDetail: canToDetail(state)
      },
      queryInfo: recommenQueryInfo.value,
      saInfo: {
        activity_from: state,
        state: state,
      }
    })
    AddOn.open({
      props: couponProps,
      on: {
        close: () => {
          if (isCartUpdated) {
            cartStore?.dispatch('fetchCartIndex')
          }
        },
        viewCart: () => {
          if (isCartUpdated) {
            cartStore?.dispatch('fetchCartIndex') // 更新购物车信息
          }
        },
        updateCart: () => {
          isCartUpdated = true
        },
        openLogin: handleOpenLogin,
      },
    })
  }

  // 免邮和活动凑单
  const handlePromotion = (opts) => {
    if(cartStore?.state?.cartAbtInfo?.CartAddTechnicalSwitch?.param?.CartAddTechnicalSwitch == 'on') {
      let type = opts?.state === 'promotion_add' ? 'promotion' : 'freeShipping'
      const freeShipItem = getFreeShipItem(opts?.popupInfoId)
      jump2Addon({
        props: {
          type,
          popupInfoId: type === 'freeShipping' && freeShipItem.freeShippingScope === 'store' ? '' : opts?.popupInfoId?.match(/\d+/)?.[0],
          mallCode: freeShipItem?.mallCode,
          storeCode: type === 'freeShipping' ? freeShipItem?.storeCode : '',
          freeShippingScope: type === 'freeShipping' ? freeShipItem?.freeShippingScope : '',
          config: {
            isClickToDetail: canToDetail(opts.state),
          },
          saInfo: {
            activity_from: opts?.state === 'promotion_add' ? 'promotion_add' : 'shipping_add',
            state: opts?.state === 'promotion_add' ? 'promotion_add' : 'shipping_add',
          },
          isBff: true,
        },
        on: {
          close: () => {
            cartStore?.dispatch('fetchCartIndex')
          },
          updateCart: async () => {

          },
          pickItem: async (payload) => { // 附属品选择响应
            if (!payload?.promotionId) return
            await cartStore?.dispatch('fetchCartIndex')
            cartStore?.commit('changeAppendageDrawerStatus', { 
              promotionId: payload.promotionId,
              fromType: payload.fromType
            })
          },
          viewCart: () => {
            cartStore?.dispatch('fetchCartIndex')
          },
        },
        openLogin: handleOpenLogin,
      })
      return
    }
    const initialProps = getAddOnPropsFromCart(opts)
    if (!initialProps?.type) return
    // console.log('购物车凑单初始化数据:=====', JSON.parse(JSON.stringify(initialProps.promotion)))
    AddOn.open({
      props: initialProps,
      on: {
        updateCart: async () => {
          const reqParams = {}
          if (opts?.state === 'shipping_add') { // 传给中间层，用于判断免邮是否更换了活动
            reqParams.njActivityId = initialProps?.promotion?.njActivityId
            reqParams.njActivityType = initialProps?.promotion?.njActivityType
            reqParams.now_level = initialProps?.promotion?.now_level
          }
          await cartStore?.dispatch('fetchCartIndex', reqParams)
          const updatedProps = getAddOnPropsFromCart(opts)
          if (updatedProps?.promotion) {
            AddOn.updateOpts({ promotion: updatedProps.promotion })
          }
        },
        pickItem: (payload) => {
          if (!payload?.promotionId) return
          cartStore?.commit('changeAppendageDrawerStatus', { 
            promotionId: payload.promotionId,
            fromType: payload.fromType
          })
        },
        openLogin: handleOpenLogin,
      },
    })
  }
  

  return {
    show(options) {
      // console.log('购物车打开凑单参数:=====', options)
      if (options?.coupon) {
        handleCoupon(options)
      } else {
        handlePromotion(options)
      }
    }
  }
}

export { useCartAddItem }
