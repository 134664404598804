<template>
  <div
    v-expose="analysisDataEvent('1-7-5-3')"
    class="cart-store__shipping"
  >
    <div class="cart-store__shipping-content">
      <sui_icon_shipping_18px
        v-if="!showDone"
        size="16px"
        color="#222"
      />
      <sui_icon_selected_16px
        v-else
        size="16px"
        color="#222"
      />
      <div class="cart-store__shipping-desc">
        <span
          class="cart-store__shipping-text"
          v-html="shippingActivityData.tip"
        >
        </span>
        <ClientOnly>
          <CountDownTime
            v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
            class="cart-store__shipping-countdown"
            :time-stamp="firstFreeShipping.countDownTime"
            :is-left-time="true"
          />
        </ClientOnly>
      </div>
    </div>
    <div
      v-if="showAdd"
      v-tap="analysisDataEvent('1-7-5-4')"
      class="cart-store__shipping-operation"
      @click="handleAdd"
    >
      {{ language.SHEIN_KEY_PC_15215 }}
      <sui_icon_more_right_12px
        size="14px"
        :is-rotate="locals.GB_cssRight"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_selected_16px, sui_icon_shipping_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import { useStore } from 'vuex'
import CountDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'

const props = defineProps({
  shippingActivityData: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: ''
  }
})

const { state } = useStore()
const AddItem = useCartAddItem()

const language = computed(() => state.language)
const locals = computed(() => state.locals)
const batchActive = computed(() => state.batchActive)
const showDone = computed(() => props.shippingActivityData?.is_fullshippingactivity == 1)
const showAdd = computed(() => props.shippingActivityData.isOpenDiff == 1 && !batchActive.value)
const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingActivityData?.freeType == 1 ?  props.shippingActivityData || {} : {}
})

const analysisDataEvent = (id) => {
  const { freeShippingScope, is_fullshippingactivity, id: shipping_method, storeCode  } = props.shippingActivityData
  const isFullStore = freeShippingScope === 'fullStore'
  const isStore = freeShippingScope === 'store'
  const isMall = freeShippingScope === 'mall'
  return {
    id,
    once: false,
    data: {
      is_fullshippingactivity: isFullStore ? '1' : is_fullshippingactivity,
      mall_code: props.mallCode || '-',
      origin_price: isFullStore ? '-' : (props.shippingActivityData?.originPrice?.usdAmountWithSymbol || '-'),
      shipping_method: (isFullStore || isStore) ? '-' : (shipping_method || '-'),
      show_position: 5,
      shipping_free_type: isMall ? 3 : 4,
      type: isFullStore ? 'allshop' : 'activity',
      store_code: (isFullStore || isStore) ? storeCode : '-',
    }
  }
}
const handleAdd = () => {
  AddItem.show({
    popupInfoId: props.shippingActivityData?.popupInfoId,
    state: 'shipping_add',
  })
}
</script>

<style lang="less">
.cart-store {
  &__shipping {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F5FCFB;
    padding: 12px 16px;
  }
  &__shipping-content {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__shipping-desc {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 8px;
  }
  &__shipping-text {
    flex: 1;
    min-width: 0;
    font-size: 13px;
    line-height: 17px;
    color: #222;
    strong {
      font-weight: 400;
      color: #C44A01;
    }
  }
  &__shipping-operation {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 12px;
    line-height: 17px;
    color: #222;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
