/**
 * @description: only for promotion and free shipping
 * @param { Object } config
 * @param { Object } config.props 
 * @param { String } config.props.type 活动类型  枚举值 promotion, user_growth, free_shipping
 * @param { String } config.props.typeId 活动typeId, 当前pwa可选
 * @param { String | Number } config.props.popupInfoId 促销活动id(如果是店铺免邮活动不需要传)
 * @param { String | Number } config.props.mallCode mallCode 免邮活动必传
 * @param { String | Number } config.props.storeCode storeCode 店铺免邮活动(必传)
 * @param { String | Number } config.props.freeShippingScope freeShippingScope 免邮活动级别 platform mall store(必传)
 * @param { string } config.props.excludeGoodIds 期望在推荐商品列表中剔除商品的商品ID, 逗号分隔
 * @param { String } config.props.includeGoodIds 期望在推荐商品列表中置顶商品的商品ID, 逗号分隔
 * @param { Object } config.props.config 弹窗配置对象 可选
 * @param { Object } config.props.config.isClickToDetail 是否点击商品跳转到商品详情页, 默认为true
 * @param { Object } config.props.config.excludePlugins 排除的插件
 * @param { Object } config.props.saInfo 埋点相关对象
 * @param { String } config.props.saInfo.state 唤起组件的BI埋点页面名称
 * @param { String } config.props.saInfo.activity_from 场景值
 * @param { Object } config.on // 回调函数
 * @return {*}
 */
export default (config) => {
  import('public/src/pages/cart_v2/components/business/add-on/index.js').then((VueComp) => {
    // 
    VueComp.default.open({
      props: {
        type: config?.props?.type,
        promotion: {
          promotion_id: Number(config?.props?.popupInfoId || 0),
        },
        config: {
          couponIsSorted: false,
          isCloseIncentivePoint: false,
          isCloseBusinessCartEntry: false,
          isBuyNow: false,
          casualCheckoutNo: '',
          continueBtnToClose: false,
          isClickToDetail: false,
          excludePlugins: [], // 快加车排除的插件
          ...(config?.props?.config || {}),
        },
        queryInfo: {
          addOnType: 2,
          sceneId: 152,
          cateIds: [],
          goodsIds: [],
          mallCode: config?.props?.mallCode || '',
          quickship_prior: 0,
          location: 'addnewhotJson',
          adp: [],
          mainGoodsIds: [],
          // goodsPrice: '0',
          // includeTspId: config?.props?.includeGoodIds || '',
          // excludeTspId: config?.props?.excludeGoodIds || '',
          // jsonRuleId: {},
          freeShippingScope: config?.props?.freeShippingScope || '',
          storeCode: config?.props?.storeCode || '',
        },
        saInfo: {
          activity_from: 'promotion_add', // wiki.pageId=1162458400
          state: 'promotion_add',
          ...(config?.props?.saInfo || {}),
        },
        isBff: true,
      },
      on: config.on
    })
  })
}
